export default function getIframeSrc() {
  const HOST_LOOKUP = {
    production: 'https://liberty-webchat.ezcater.com/',
    staging: 'https://liberty-webchat.staging-ezcater.com/',
    dev1: 'https://liberty-webchat.dev1.sandbox-ezcater.com/',
  };

  if (/\.ezcater\.com$/.test(window.location.hostname)) {
    return HOST_LOOKUP.production;
  } else if (/rainforestqa\.sandbox-ezcater\.com$/.test(window.location.hostname)) {
    return HOST_LOOKUP.staging;
  } else if (/\.sandbox-ezcater\.com$/.test(window.location.hostname)) {
    return HOST_LOOKUP.dev1;
  }

  return HOST_LOOKUP.staging;
}
