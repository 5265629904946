/**
 * From a target object, create a new object containing only the keys specified in the keys parameter
 *
 * @param target The object to pick keys from
 * @param keys the keys to choose
 * @returns a new object containing all key/value pairs specified in `keys`
 * @example
 * ```ts
 * const manga = {
 *   title: 'One Piece',
 *   author: 'Eiichiro Oda',
 *   synopsis: 'Follows the adventures of Monkey D. Luffy and his pirate crew in order to find the greatest treasure ever left by the legendary Pirate, Gold Roger. The famous mystery treasure named "One Piece".',
 * }
 * pick(['title'], manga);
 * // { 'title': 'One Piece' }
 * ```
 */
export default function pick<T extends Record<PropertyKey, unknown>, K extends keyof T>(
  keys: K[],
  target: T,
) {
  return keys.reduce<Pick<T, K>>(
    (result, key) => {
      if (target[key]) {
        result[key] = target[key];
      }
      return result;
    },
    {} as Pick<T, K>,
  );
}
