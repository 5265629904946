import {createContext, useMemo} from 'react';

import {type IdentityQuery} from '@/graphql/types';
import {useIdentityQuery} from '@/graphql/types';

export type IdentityContextType = {
  data: IdentityQuery | null;
  isAdmin: boolean;
  isLoggedIn: boolean;
  loading: boolean;
} | null;

export const IdentityContext = createContext<IdentityContextType>(null);

const IdentityProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {data, loading} = useIdentityQuery({
    errorPolicy: 'ignore',
  });
  const providerValue = useMemo(
    () => ({
      data: data ?? null,
      isAdmin: Boolean(data?.me?.adminAccount?.id),
      isLoggedIn: Boolean(data?.me?.consumerAccount),
      loading,
    }),
    [data, loading],
  );

  return <IdentityContext.Provider value={providerValue}>{children}</IdentityContext.Provider>;
};

export default IdentityProvider;
