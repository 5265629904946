import {type IdentityContextType} from '@/components/IdentityProvider';

export const signedOutIdentity: IdentityContextType = {
  data: {
    currentUserTrackingId: '2',
    currentUserHashedTrackingId: '2',
    identity: {
      id: '2',
      isEligibleForPricingFeeExperiment: false,
      __typename: 'MarketplaceIdentity',
    },
    me: {
      id: 'me-2',
      consumerAccount: null,
    },
    __typename: 'Query',
  },
  isAdmin: false,
  isLoggedIn: false,
  loading: false,
};
