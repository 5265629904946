const appColors = {
  black: '#000',
  white: '#fff',
  red: '#ff585d',
  redError: '#d6262b',
  blueBright: '#3f61ff',
  borderGray: '#e6e9eb',
  gray: '#d9d9d9',

  /* brand colors */
  orange: '#ff6b1e',
  blue: '#3a64df',
  kaleGreen: '#1d3c34',
  ezGreen: '#008054',
  cilantroGreen: '#034a34',
  ezGreenLight: '#e6f7f1',
  blackOlive: '#151515',
  ezGold: '#e8a801',
  darkGray: '#323232',

  /* primary colors */
  primary90: '#f0faf7',

  /* grays */
  gray100: '#f4f7f8' /* lightestGray */,
  gray120: '#f5f7f7' /* neutral120 */,
  gray130: '#e5e6e6',
  gray140: '#b2b3b3',
  gray200: '#eaeaea',
  gray250: '#ced4d9' /* light grayish blue */,
  gray300: '#cccfd5' /* lightGray */,
  gray400: '#999' /* mediumGray */,
  gray500: '#565a5c' /* darkGray */,
  gray600: '#32353e' /* darkerGray */,
  peppercorn200: '#d6d9e1',
  peppercorn300: '#989ca8',
  peppercorn800: '#212223',

  /* blues */
  blue100: '#f6fbff',
  blue200: '#ebf7ff',
  blue500: '#77b1e2',
  blue600: '#3e90d6',
  blue700: '#1e70bf',
  blue800: '#007ac9' /* darkerBlue */,

  /* reds */
  red200: '#fdefef',
  red600: '#ec5353' /* red */,

  /* yellows */
  yellow500: '#f4d689',
  yellow600: '#e9a801',
  starsYellow: '#f4d142',

  /* greens */
  ezGreen500: '#006444',
  ezGreen600: '#004E34',

  shadedLightestGray: '#e7eaeb',
};

export default appColors;
