import {useCallback} from 'react';

import {useEzRewardsUserAssignedPromo} from '@/components/EzRewardsPromosProvider';
import useIsOnMenuPage from './useIsOnMenuPage';

const useLibertyWebchatPositioning = () => {
  const {isOnMenuPage} = useIsOnMenuPage();
  const {promo} = useEzRewardsUserAssignedPromo();

  const libertyWebchatPosition = useCallback(() => {
    if (promo && !isOnMenuPage) {
      return 'right-[60px]';
    } else if (promo) {
      return 'right-[405px]';
    } else if (isOnMenuPage) {
      return 'right-[345px]';
    }
  }, [promo, isOnMenuPage]);

  return {libertyWebchatPosition};
};

export default useLibertyWebchatPositioning;
