import {type HomePageQuery} from '@/graphql/types';
import RequiredPath from '@/utils/RequiredPath';

type AddressConnection = RequiredPath<HomePageQuery, ['me', 'consumerAccount', 'recentAddresses']>;

const addresses = [
  {name: 'ezCater Office', street: '40 Water St'},
  {name: 'Summer St Branch', street: '20 Summer St'},
  {name: 'Mass Ave Branch', street: '100 Massachusetts Ave'},
  {name: 'Main St Branch', street: '200 Main St'},
  {name: 'Broadway Branch', street: '300 Broadway'},
];

export const getAddressConnections = (numResults: number) =>
  ({
    __typename: 'AddressConnection',
    edges: Array.from({length: numResults}, (_, i) => ({
      __typename: 'AddressEdge',
      node: {
        __typename: 'Address',
        id: `address-test-id-${i + 1}`,
        uuid: `address-test-uuid-${i + 1}`,
        addressBookEntryId: i,
        city: 'Boston',
        fullAddress: `${addresses[i % addresses.length].street}, Boston, MA 02109`,
        latitude: 42.35756,
        longitude: -71.05677,
        name: addresses[i % addresses.length].name,
        state: 'MA',
        street: addresses[i % addresses.length].street,
        street2: null,
        zip: '02109',
      },
    })),
    totalCount: numResults,
  }) satisfies AddressConnection;
