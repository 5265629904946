export const safeLocalStorage = {
  getItem(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (_e) {
      return null;
    }
  },
  removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (_e) {
      /* no-op */
    }
  },
  setItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (_e) {
      /* no-op */
    }
  },
};

export const safeSessionStorage = {
  getItem(key: string) {
    try {
      return sessionStorage.getItem(key);
    } catch (_e) {
      return null;
    }
  },
  removeItem(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (_e) {
      /* no-op */
    }
  },
  setItem(key: string, value: string) {
    try {
      sessionStorage.setItem(key, value);
    } catch (_e) {
      /* no-op */
    }
  },
};
