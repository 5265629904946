import {useMemo} from 'react';
import {type ApolloClient, type NormalizedCacheObject} from '@apollo/client';

import {initializeApollo} from '@/apollo';

const useApollo = (
  initialState?: NormalizedCacheObject | null,
): ApolloClient<NormalizedCacheObject> =>
  useMemo(() => initializeApollo({initialState}), [initialState]);

export default useApollo;
