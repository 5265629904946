// @ts-strict-ignore
import {useMemo} from 'react';
import {type ReactElement} from 'react';
import {type FormikErrors} from 'formik';
import {Form, Formik} from 'formik';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import {useContactUsFormQuery, useSubmitContactUsFormMutation} from '@/graphql/types';
import {PRIVACY_PATH} from '@/paths';
import {gtm} from '@/utils';
import {contactUsFormSubmitPayload} from '@/utils/googleAnalyticsEvents';
import ALERT_TYPES from './alertTypes';
import SelectField from './SelectField';
import SubmitButton from './SubmitButton';
import TextField from './TextField';

type FormValues = {
  fullName: string;
  email: string;
  orderId?: string | null;
  message: string;
};

const formatLabelForSelect = order => {
  if (order.formattedOrderNumber === '#' && order.caterer?.name)
    return `New Order, ${order.caterer?.name}`;
  if (order.formattedOrderNumber === '#') return `New Order`;

  const labelFragments = [order.formattedOrderNumber];

  const locationOrCatererFragment = order.event.address?.nameOrLocation ?? order.caterer?.name;
  if (locationOrCatererFragment) labelFragments.push(locationOrCatererFragment);

  return labelFragments.join(', ');
};

const formatOrderForSelect = order => ({
  label: formatLabelForSelect(order),
  value: order.id,
});

const extractErrors = ({graphQLErrors}) => {
  if (graphQLErrors == null) return {};

  const errors = {};
  graphQLErrors.forEach(({key, message, type, name}) => {
    if (type !== 'field') return;
    errors[key] = `${name} ${message}`;
  });
  return errors;
};

type ContactUsFormProps = {
  showAlert: (status: string) => void;
  toggleContactUsModal: () => void;
};

const ContactUsForm = ({showAlert, toggleContactUsModal}: ContactUsFormProps): ReactElement => {
  const {t} = useTranslation('common');
  const {data, loading} = useContactUsFormQuery({partialRefetch: true, errorPolicy: 'all'});
  const [submitContactUs] = useSubmitContactUsFormMutation();
  const recentOrders = data?.me?.consumerAccount?.orders?.nodes;
  const orderOptions = useMemo(() => {
    const options = [{label: '--', value: ''}];

    if (recentOrders) options.push(...recentOrders.map(formatOrderForSelect));

    return options;
  }, [recentOrders]);

  if (loading) {
    return <div className="flex w-3/5 items-center justify-center bg-gray-100">Loading...</div>;
  }

  const validateForm = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (values.fullName === '') errors.fullName = t('components.ContactUs.form.errors.name');
    if (values.email === '') errors.email = t('components.ContactUs.form.errors.email');
    if (values.message === '') errors.message = t('components.ContactUs.form.errors.message');
    return errors;
  };

  const trackGTMEvent = () => {
    gtm.dataLayer(
      contactUsFormSubmitPayload({
        formId: 'contact_us',
        formHost: 'ezcater',
        clickText: t('components.ContactUs.form.sendMessage'),
      }),
    );
  };

  return (
    <div className="bg-gray-100 py-6 pl-4 pr-4 tablet:py-16 tablet:pl-12 tablet:pr-16">
      <p className="mb-6 mt-2 font-bold">{t('components.ContactUs.form.description')}</p>
      <Formik
        initialValues={{
          fullName: data?.me?.consumerAccount?.fullName ?? '',
          email: data?.me?.consumerAccount?.email ?? '',
          message: '',
        }}
        validate={validateForm}
        onSubmit={({fullName, email, message}, {setErrors}) =>
          submitContactUs({variables: {fullName, email, message}})
            .then(() => {
              showAlert(ALERT_TYPES.SUCCESS);
              toggleContactUsModal();
              trackGTMEvent();
            })
            .catch(response => {
              showAlert(ALERT_TYPES.ERROR);
              setErrors(extractErrors(response));
            })
        }
      >
        <Form>
          <div className="mb-3 flex justify-between gap-3">
            <div className="flex-auto">
              <TextField
                name="fullName"
                label={t('components.ContactUs.form.nameLabel')}
                placeholder={t('components.ContactUs.form.namePlaceholder')}
              />
            </div>
            <div className="flex-auto">
              <TextField
                name="email"
                label={t('components.ContactUs.form.emailLabel')}
                placeholder={t('components.ContactUs.form.emailPlaceholder')}
              />
            </div>
          </div>
          {orderOptions.length > 1 && (
            <div className="mb-3 flex justify-between">
              <div className="flex-auto">
                <SelectField
                  name="orderId"
                  label={
                    <Trans
                      i18nKey={`common:components.ContactUs.form.orderNumber`}
                      components={{em: <em />}}
                    />
                  }
                  options={orderOptions}
                />
              </div>
            </div>
          )}
          <div className="mb-3 flex justify-between">
            <div className="flex-auto">
              <TextField
                name="message"
                type="textarea"
                label={t('components.ContactUs.form.messageLabel')}
                maxLength={1000}
                placeholder={t('components.ContactUs.form.addMessage')}
              />
            </div>
          </div>
          <div className="flex flex-col-reverse items-center justify-between gap-2 tablet:flex-row tablet:gap-0">
            <a href={PRIVACY_PATH} target="_blank" rel="noopener noreferrer">
              {t('components.ContactUs.form.privacyPolicy')}
            </a>
            <SubmitButton>{t('components.ContactUs.form.sendMessage')}</SubmitButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactUsForm;
