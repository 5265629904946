const gtm = {
  dataLayer(dataLayer: Record<string, any>, dataLayerName: 'dataLayer' = 'dataLayer'): void {
    if (typeof window === 'undefined') {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn(
          'Called gtm.dataLayer during SSR, you most likely want this in a useEffect hook',
        );
      }
      return;
    }

    if (!window[dataLayerName]) window[dataLayerName] = [];
    window[dataLayerName].push(dataLayer);
  },
} as const;

export default gtm;
