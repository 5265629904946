// 👋
// This file contains a mapping of experiments to their feature flag.
//
// By pairing an experiment to a feature flag it will force
// the <ExperimentExperience /> component to act as if the user
// is in the "control" group when that feature flag is disabled
//
// This is optional, experiments that are not gated behind a
// feature flag will work as normal without an entry in this object!
//

import Experiments from './Experiments';
import FeatureFlags from './FeatureFlags';

export type ExperimentsConfig = Partial<Record<Experiments, FeatureFlags>>;

const experimentsConfig: ExperimentsConfig = {
  // add experimentName: featureFlagName mapping here, e.g.,
  // [Experiments.EXP_NAME]: FeatureFlags.FF_NAME
  [Experiments.GroupOrderBannerToButton]: FeatureFlags.GroupOrderBannerToButton,
  [Experiments.MultiCarouselSpike]: FeatureFlags.MultiCarouselSpike,
  [Experiments.NewAppBar]: FeatureFlags.NewAppBar,
  [Experiments.BrandNamesInSearchTypeahead]: FeatureFlags.BrandNamesInSearchTypeahead,
  [Experiments.NewCheckout]: FeatureFlags.NewCheckout,
  [Experiments.AllowLoggedOutCatererSave]: FeatureFlags.AllowLoggedOutCatererSave,
  [Experiments.SaveRestaurantsInReviewModal]: FeatureFlags.SaveRestaurantsInReviewModal,
  [Experiments.SavedCaterersCarouselOnSearch]: FeatureFlags.SavedCaterersCarouselOnSearch,
  [Experiments.SaveRestaurantAnimation]: FeatureFlags.SaveRestaurantAnimation,
  [Experiments.EzRewardsForSavedCaterer]: FeatureFlags.EzRewardsForSavedCaterer,
};

export default experimentsConfig;
