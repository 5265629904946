// session storage
export const STORAGE_ITEM_TRUTHY = 'true';
export const REWARDS_BANNER_VIEWED_ITEM = 'viewedRewardsBanner';
export const REWARDS_BANNER_VISIBILITY_PREFERENCE_ITEM = 'rewardsBannerVisibilityPreference';

// event messages
export const MOBILE_VIEW_CART_BUTTON_SHOWN = 'Mobile View Cart Button Shown';
export const MOBILE_VIEW_CART_BUTTON_HIDDEN = 'Mobile View Cart Button Hidden';

// tracking
export const REWARDS_BANNER_SHOWN_TRACKING = 'rewards-banner-shown';
export const REWARDS_BANNER_CLOSED_TRACKING = 'rewards-banner-closed';
export const REWARDS_BANNER_OPENED_TRACKING = 'rewards-banner-opened';

// test ids
export const CLOSE_ICON_ID = 'close-icon';
export const OPEN_ICON_ID = 'open-icon';

// constant values
export const MARKETPLACE = 'marketplace';
export const EZO = 'ezOrdering';
export const OPEN = 'open';
export const CLOSED = 'closed';
