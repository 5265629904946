import {useEffect} from 'react';
import {usePrevious} from 'react-use';
import {useApolloClient} from '@apollo/client';
import {datadogRum} from '@datadog/browser-rum';
import {useRouter} from 'next/router';

import {
  TrackPageViewDocument,
  type TrackPageViewMutation,
  type TrackPageViewMutationVariables,
  type TrackTouchMutation,
  TrackTouchMutationDocument,
  type TrackTouchMutationVariables,
} from '@/graphql/types';
import useTracking from '@/hooks/useTracking';

type Props = {
  pageTrackingProps?: StoreNextPageDataProps['pageTrackingProps'];
};

const safeIdleCallback = (fn: () => void) => {
  if (typeof requestIdleCallback === 'function') {
    requestIdleCallback(fn);
  } else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    setTimeout(fn, 1);
  }
};

/**
 * By default we use the pathname as the page name for analytics,
 * but some pages have custom names that we want to use instead.
 *
 * This object contains the mapping of pathnames to page names,
 * if a path isn't in here then the pathname will be used instead.
 */
export const pageNameOverrideDictionary = {
  '/': 'homepage index',
  '/catering/[catererUrlId]/[[...date]]': 'CatererShow',
  '/catering/pvt/[catererUrlId]/[[...date]]': 'CatererShow',
  '/catering/search/[orderId]': 'store/caterer_search show',
  '/expense-reporting': 'my-account/expense-reporting',
  '/my-account/addresses/[addressId]': 'my-account/address-show',
  '/my-account/addresses/[addressId]/edit': 'my-account/edit-address',
  '/my-account/addresses/new': 'my-account/addresses/new',
  '/my-account/group-polls': 'my-account/group-polls',
  '/my-account/group-polls/[groupPollId]': 'my-account/group-polls/[groupPollId]',
  '/my-account/orders': 'my-account/orders',
  '/my-account/orders/[orderId]': 'my-account/view-order',
  '/my-account/orders/[orderId]/review': 'Reviews',
  '/my-account/orders/calendar-view': 'my-account/orders/calendar-view',
  '/my-account/payment-methods': 'my-account/payment-methods',
  '/my-account/personal-information': 'my-account/personal-information',
  '/my-account/receipts': 'my-account/receipts',
  '/my-account/reviews': 'my-account/reviews',
  '/my-account/suggest-caterer': 'my-account/suggest-caterer',
};

const PageTracking: React.FC<Props> = ({pageTrackingProps}) => {
  const apolloClient = useApolloClient();
  const {pathname, asPath, query} = useRouter();
  const previousLocation = usePrevious(asPath);
  const {trackPageView} = useTracking();

  const pageName =
    pageNameOverrideDictionary[pathname as keyof typeof pageNameOverrideDictionary] || pathname;
  const currentPath = asPath.split('?', 1)[0];

  useEffect(() => {
    datadogRum.setGlobalContextProperty('router.query', query);
  }, [query]);

  useEffect(() => {
    const url = `${window.location.origin}${asPath}`;
    const referrerUrl = previousLocation
      ? `${window.location.origin}${previousLocation}`
      : document.referrer;

    safeIdleCallback(() => {
      apolloClient.mutate<TrackTouchMutation, TrackTouchMutationVariables>({
        mutation: TrackTouchMutationDocument,
        variables: {url, referrerUrl},
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      });
    });
    // we only want to track touch events on the initial page load and when the URL path changes
    // we can safely break rules of hooks to enable this behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  useEffect(() => {
    const url = `${window.location.origin}${asPath}`;
    const referrerUrl = previousLocation
      ? `${window.location.origin}${previousLocation}`
      : document.referrer;

    safeIdleCallback(() => {
      apolloClient.mutate<TrackPageViewMutation, TrackPageViewMutationVariables>({
        mutation: TrackPageViewDocument,
        variables: {
          pageName,
          url,
          referrerUrl,
          ...pageTrackingProps,
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      });
    });
    // we only want to track page view events on the initial page load, when the URL path changes,
    // and when the page tracking props change
    // we can safely break rules of hooks to enable this behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, pageTrackingProps]);

  useEffect(() => {
    const url = `${window.location.origin}${asPath}`;

    trackPageView('', pageName, {
      path: asPath,
      url: url,
    });
  }, [pageName, asPath, trackPageView]);

  return null;
};

export default PageTracking;
