import {type IncomingMessage} from 'http';
import url from 'url';

import {type ApolloLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import getConfig from 'next/config';

const createHeaderLink = (req?: IncomingMessage): ApolloLink => {
  let ssrHeaders = {};
  if (typeof window === 'undefined') {
    const {serverRuntimeConfig} = getConfig();
    if (serverRuntimeConfig.ssrOrigin) {
      const ssrOrigin = url.parse(serverRuntimeConfig.ssrOrigin);

      ssrHeaders = {
        origin: serverRuntimeConfig.ssrOrigin,
        'X-FORWARDED-HOST': ssrOrigin.hostname,
        'X-FORWARDED-PROTO': ssrOrigin.protocol === 'https:' ? 'https' : undefined,
      };
    }
  }

  return setContext((graphqlRequest, {headers = {}}) => ({
    headers: {
      ...headers,
      ...ssrHeaders,
      ...(typeof window === 'undefined' &&
        req && {
          'User-Agent': req.headers['user-agent'],
          'X-REQUEST-ID': req.context?.requestId,
          'X-Tenable-Was-Scan-Id': req.headers['x-tenable-was-scan-id'] as string | undefined,
          'X-Forwarded-For': req.ip,
        }),
      'X-EZCATER-SERVICE-NAME': 'store-next',
      'X-EZCATER-SOURCE': typeof window === 'undefined' ? 'server' : 'client',
    },
  }));
};

export default createHeaderLink;
