// @ts-strict-ignore
import type React from 'react';
import {useCallback} from 'react';
import {EzField} from '@ezcater/recipe';
import {useFormikContext} from 'formik';

type SelectFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  options?: {
    label: string;
    value: string | number | boolean;
    disabled?: boolean;
  }[];
  label: React.ReactNode;
};

const SelectField: React.FC<SelectFieldProps> = ({name, options, label, ...props}) => {
  const {getFieldMeta, setFieldValue, setFieldTouched} = useFormikContext();
  const {value, error, touched} = getFieldMeta(name);

  const onChange = useCallback(
    event => {
      setFieldValue(name, event.target.value);
    },
    [name, setFieldValue],
  );

  const onBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <EzField
      {...props}
      type="select"
      name={name}
      value={value as string}
      options={options}
      label={label as any}
      onChange={onChange}
      onBlur={onBlur}
      touched={touched}
      error={error}
    />
  );
};

export default SelectField;
