import {createContext, useContext, useMemo} from 'react';

export const PageReferrerContext = createContext({
  isFromEzCater: false,
  isFromEzCaterSearch: false,
});

export const usePageReferrer = () => useContext(PageReferrerContext);

type Props = {
  referrer?: {
    isFromEzCater: boolean;
    isFromEzCaterSearch: boolean;
  } | null;
};

const PageReferrerProvider: React.FC<React.PropsWithChildren<Props>> = ({children, referrer}) => {
  return (
    <PageReferrerContext.Provider
      value={useMemo(
        () => ({
          isFromEzCater: !!referrer?.isFromEzCater,
          isFromEzCaterSearch: !!referrer?.isFromEzCaterSearch,
        }),
        [referrer?.isFromEzCater, referrer?.isFromEzCaterSearch],
      )}
    >
      {children}
    </PageReferrerContext.Provider>
  );
};

export default PageReferrerProvider;
