/* eslint-disable @next/next/no-html-link-for-pages */
import {useEffect} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import Image from 'next/image';
import {useRouter} from 'next/router';

import logoDark from '@/assets/images/brand/logo-dark.png';
import reload from '@/utils/reload';

type OhCrumbsProps = {
  allowBrowserRefresh?: boolean;
  is500?: boolean;
  error?: unknown;
  componentStack?: string;
};

const OhCrumbs: React.FC<OhCrumbsProps> = ({
  allowBrowserRefresh = false,
  is500 = false,
  error,
  componentStack,
}) => {
  const {pathname, query} = useRouter();

  useEffect(() => {
    if (error) datadogRum.addError(error, {componentStack, url: {pathname, query}});
    datadogRum.addAction('error-component-rendered', {
      component: 'OhCrumbs',
      error,
      componentStack,
      is500,
      url: {pathname, query},
    });
  }, [is500, error, componentStack, pathname, query]);

  return (
    <div className="mx-auto mt-8 flex max-w-lg flex-col gap-8 rounded-lg border border-gray-300 p-8 text-center">
      <a href="/" className="block">
        <Image className="mx-auto" alt="ezCater Logo" src={logoDark} priority />
      </a>
      <h1 className="text-4xl font-extrabold text-blue-800">Oh crumbs.</h1>
      <div className="flex flex-col gap-4 text-lg">
        <p>Looks like something went wrong.</p>
        <p>
          Sorry things aren&#39;t so ez right now.
          <br />
          If you need immediate assistance,
          <br />
          please call 1-800-488-1803.
        </p>
        {allowBrowserRefresh ? (
          <button
            type="button"
            onClick={reload}
            className="inline-block appearance-none self-center whitespace-nowrap rounded border border-blue-700 bg-ez-blue px-4 py-2 text-center text-white transition-colors duration-250 ease-out hover:bg-blue-800"
          >
            Reload
          </button>
        ) : (
          <a href="/" className="block self-center">
            Return to ezCater
          </a>
        )}
      </div>
    </div>
  );
};

export default OhCrumbs;
