import type React from 'react';
import {type ReactElement} from 'react';
import {EzField} from '@ezcater/recipe';
import {useFormikContext} from 'formik';

type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  type?: 'number' | 'password' | 'textarea' | 'text';
  label: string;
};

const TextField = ({name, type = 'text', ...props}: TextFieldProps): ReactElement => {
  const {getFieldProps, getFieldMeta} = useFormikContext();
  const {onChange, onBlur} = getFieldProps(name);
  const {value, error, touched} = getFieldMeta(name);

  return (
    <EzField
      {...props}
      type={type}
      name={name}
      value={value as string}
      onChange={onChange}
      onBlur={onBlur}
      touched={touched}
      error={error}
    />
  );
};

export default TextField;
