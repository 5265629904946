// @ts-strict-ignore
import {EzFlashMessage} from '@ezcater/recipe';

import useFlashMessage from '@/hooks/useFlashMessage';

const FlashMessage = () => {
  const {message, setMessage} = useFlashMessage();

  if (!Object.keys(message).length || !message.text || !message.use) return null;

  const onAutohide = () => setMessage({});

  return (
    <div className="fixed bottom-5 left-3 z-50 tablet:left-5">
      <EzFlashMessage
        autohide
        autohideDuration={message.duration || 4000}
        onAutohide={onAutohide}
        use={message.use}
        headline={message.headline}
      >
        {message.text}
      </EzFlashMessage>
    </div>
  );
};

export default FlashMessage;
