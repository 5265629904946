import {type HomePageQuery, UserIndustryEnum, UserRegistrationStatusEnum} from '@/graphql/types';

type Identity = NonNullable<HomePageQuery['me']>;
type IdentityType = 'admin' | 'corp' | 'corpAdmin' | 'default';

export const getIdentity = (identityType: IdentityType = 'default') =>
  ({
    __typename: 'Identity',
    id: 'identity-test-id-1',
    adminAccount:
      identityType === 'admin'
        ? {__typename: 'AdminAccount', id: 'admin-account-test-id-1', isMasquerading: false}
        : null,
    consumerAccount: {
      __typename: 'ConsumerAccount',
      id: 'consumer-account-test-id-1',
      canAccessConcur: true,
      canAccessCorpPortal: identityType === 'corp' || identityType === 'corpAdmin',
      canCollectOwnEzRewards: identityType !== 'corp',
      canEarnEzRewards: identityType !== 'corp' && identityType !== 'corpAdmin',
      canEarnReferralRewards: identityType !== 'corp' && identityType !== 'corpAdmin',
      canPayWithEzRewards: identityType !== 'corp',
      corporateAccount:
        identityType === 'corp' || identityType === 'corpAdmin'
          ? {
              __typename: 'CorporateAccount',
              id: 'corporate-account-test-id-1',
              isCharitable: true,
              isParent: false,
              name: 'ezCater',
            }
          : null,
      email: 'lindabelcher@ezcater.com',
      enrolledInEzRewards: identityType !== 'corp',
      ezRewardsAccount:
        identityType === 'corp'
          ? null
          : {__typename: 'EzRewardsAccount', id: 'ez-rewards-account-test-id', balance: 10389},

      firstName: 'Linda',
      firstOrderDetails: {
        __typename: 'FirstOrderDetails',
        id: 'first-order-details-test-id-1',
        order: null,
        submittedFeedback: false,
      },
      fullName: 'Linda Belcher',
      identityId: 'consumer-account-identity-test-id-1',
      industry: UserIndustryEnum.Business,
      ownedCorporateAccounts: [],
      registrationStatus: UserRegistrationStatusEnum.Registered,
      shouldLoadFullstory: false,
    },
    previouslyViewedCaterers: {
      __typename: 'CatererConnection',
      edges: [],
    },
  }) satisfies Identity;
