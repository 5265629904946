import getConfig from 'next/config';

const trimTrailingSlash = (path: string): string =>
  path.substr(-1, 1) === '/' ? path.slice(0, -1) : path;

const {
  publicRuntimeConfig: {assetHost},
} = getConfig();
const prefix = trimTrailingSlash(assetHost || '');

const assetPath = (assetUrl: string): string => `${prefix}${assetUrl}`;

export default assetPath;
