import {createContext, ReactNode} from 'react';

import redirect from '@/utils/redirect';

export const RedirectionContext = createContext(redirect);
type RedirectionProviderProps = {
  children: ReactNode;
};

const RedirectionProvider = ({children}: RedirectionProviderProps) => {
  return <RedirectionContext.Provider value={redirect}>{children}</RedirectionContext.Provider>;
};

export default RedirectionProvider;
