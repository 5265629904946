const mutinyInitialize = () => {
  const mutiny = ((window as any).mutiny = (window as any).mutiny || {});

  if (!mutiny.client) {
    mutiny.client = {_queue: {}} as MutinyClient;
    const promiseMethods = ['identify', 'trackConversion'] as const;
    const allMethods = [...promiseMethods, 'defaultOptOut', 'optOut', 'optIn'] as const;

    const factory = (method: (typeof allMethods)[number]) => {
      return function (...args: any[]) {
        mutiny.client!._queue[method] = mutiny.client!._queue[method] || [];
        if (promiseMethods.includes(method as any)) {
          return new Promise<void>((resolve, reject) => {
            mutiny.client!._queue[method].push({args, resolve, reject});
          });
        } else {
          mutiny.client!._queue[method].push({args});
        }
      };
    };

    allMethods.forEach(method => {
      mutiny.client![method] = factory(method);
    });
  }
};

export default mutinyInitialize;
