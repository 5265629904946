import {useContext} from 'react';
import invariant from 'tiny-invariant';

import {type TrackerContextType, TrackingContext} from '@/components/TrackingProvider';

const useTracking = (): TrackerContextType => {
  const contextValue = useContext(TrackingContext);

  invariant(contextValue != null, 'useTracking() was called but the TrackingProvider was not used');

  return contextValue;
};

export default useTracking;
