import {useContext, useRef} from 'react';

import {IdentityContext, type IdentityContextType} from '@/components/IdentityProvider';

const useIdentity = (): NonNullable<IdentityContextType> => {
  const contextValue = useContext(IdentityContext);

  if (__DEV__ && !contextValue) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasWarned = useRef<boolean>(false);
    if (!hasWarned.current) {
      hasWarned.current = true;
      // eslint-disable-next-line no-console
      console.warn('useIdentity() was called but the IdentityContext was not used');
    }
  }

  return contextValue || {data: null, isAdmin: false, isLoggedIn: false, loading: false};
};

export default useIdentity;
