const appFonts = {
  /* font-size */
  textXs: '0.75rem',
  textSm: '0.875rem',
  textBase: '1rem' /* medium */,
  textLg: '1.125rem',
  textXl: '1.25rem',
  text2xl: '1.5rem',
  text3xl: '1.875rem',
  text4xl: '2.25rem',
  text5xl: '3rem',
  text6xl: '4rem',
  text8xl: '6rem',

  /* font */
  fontFamilyBase:
    "Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji','Segoe UI Symbol'",

  fontWeightLight: '300',
  fontWeightBase: '400',
  fontWeightSemiBold: '600',
  fontWeightBold: '700',
  fontWeightExtraBold: '900',
};

export default appFonts;
