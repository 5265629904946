import getConfig from 'next/config';
import {compile, type PathFunction} from 'path-to-regexp';
import queryString from 'query-string';

import {EZ_RAILS_PATHS, LANDINGS_PATHS} from '../paths';

const pathMap: Record<string, PathFunction<object>> = {};

const pathPrefix = (() => {
  if (process.env.NODE_ENV === 'development')
    return (path: string) => {
      if (EZ_RAILS_PATHS.has(path)) {
        return 'http://localhost:3000';
      }
      if (LANDINGS_PATHS.has(path)) {
        return 'http://localhost:21090';
      }
      return '';
    };

  if (process.env.VERCEL === '1')
    return (path: string) => {
      if (EZ_RAILS_PATHS.has(path) || LANDINGS_PATHS.has(path)) {
        return urlForEnv('staging');
      }
      return '';
    };

  return () => '';
})();

const stringifyParamValues = (params: Record<string, string | any>) =>
  Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, value == null ? value : String(value)]),
  );

type CompilePath = (
  path: string,
  params?: Record<string, string | any>,
  queryParams?: Record<string, any>,
  stringifyOptions?: queryString.StringifyOptions,
) => string;

const compilePath: CompilePath = (path, params = {}, queryParams = {}, stringifyOptions = {}) => {
  const stringifiedQueryParams = queryString.stringify(queryParams, stringifyOptions);
  const query = stringifiedQueryParams ? `?${stringifiedQueryParams}` : '';
  if (!pathMap[path]) pathMap[path] = compile(path);
  return `${pathPrefix(path)}${pathMap[path](stringifyParamValues(params))}${query}`;
};

const urlForEnv = (env: string) => {
  switch (env) {
    case 'development':
      return 'http://localhost:22030';
    case 'dev1':
      return 'https://www.dev1.sandbox-ezcater.com';
    case 'rainforestqa':
      return 'https://www.rainforestqa.sandbox-ezcater.com';
    case 'staging':
      return 'https://www.staging-ezcater.com';
    default:
      return 'https://www.ezcater.com';
  }
};

export const fullUrlForPath = (path: string): string => {
  const {publicRuntimeConfig} = getConfig();

  const basePath = urlForEnv(publicRuntimeConfig.environment);
  if (path.startsWith('/')) {
    return `${basePath}${path}`;
  }
  return `${basePath}/${path}`;
};

export default compilePath;
