import './globals';

import getConfig from 'next/config';
import Script from 'next/script';

import FeatureFlags from '@/FeatureFlags';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';
import {useDatadogRumSetup} from './useDatadogRumSetup';
import useFullStorySetup from './useFullStorySetup';
import useGoogleAnalyticsCookieEventNotifier from './useGoogleAnalyticsCookieEventNotifier';
import useTrackingIdNotifier from './useTrackingIdNotifier';

const {publicRuntimeConfig} = getConfig();
const polyfillVersion = '4.8.0';
const polyfills = [
  'smoothscroll',
  'AbortController',
  'requestIdleCallback',
  'IntersectionObserver',
  'Intl',
  'Intl.Locale',
  'Intl.~locale.en',
  'Intl.getCanonicalLocales',
  'Intl.DateTimeFormat',
  'Intl.NumberFormat',
  'Intl.PluralRules',
].join(',');
const polyfillUrl = `https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=${encodeURIComponent(
  polyfillVersion,
)}&features=${encodeURIComponent(polyfills)}`;

/*
 * For reasonings and an example of why we implement 3rd party scripts this way, see this post:
 * https://ezcater.atlassian.net/wiki/spaces/POL/pages/4218880089/Third+Party+Scripts+in+Store+Next
 */

const GlobalScripts: React.FC = () => {
  const {data} = useIdentity();
  const fsAnonymousEnabled = useFeatureFlag(FeatureFlags.FullStoryAnonymous);
  const fsEngaged = useFeatureFlag(FeatureFlags.FullStoryEngage);
  const appsFlyerEnabled =
    useFeatureFlag(FeatureFlags.AppsFlyerEnabled) && !!publicRuntimeConfig?.appsFlyerWebKey;

  const enableFullStory =
    Boolean(publicRuntimeConfig.fullStoryOrgKey) &&
    fsEngaged &&
    (data?.me?.consumerAccount != null
      ? data.me.consumerAccount.shouldLoadFullstory
      : fsAnonymousEnabled);

  useTrackingIdNotifier(data?.currentUserTrackingId, data?.currentUserHashedTrackingId);

  useGoogleAnalyticsCookieEventNotifier();

  useFullStorySetup({
    isEnabled: enableFullStory,
    trackingId: data?.currentUserTrackingId,
    consumerAccount: data?.me?.consumerAccount,
  });

  useDatadogRumSetup({
    trackingId: data?.currentUserTrackingId,
    consumerAccount: data?.me?.consumerAccount,
  });

  return (
    <>
      {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
      <Script src={polyfillUrl} strategy="beforeInteractive" crossOrigin="anonymous" />
      {publicRuntimeConfig.gtmId && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtm.js?id=${encodeURIComponent(
            publicRuntimeConfig.gtmId,
          )}`}
        />
      )}
      {enableFullStory && (
        <Script
          strategy="afterInteractive"
          src="https://edge.fullstory.com/s/fs.js"
          crossOrigin="anonymous"
        />
      )}
      {appsFlyerEnabled && (
        <Script strategy="afterInteractive" src="https://websdk.appsflyer.com/?st=banners" />
      )}
    </>
  );
};

export default GlobalScripts;
