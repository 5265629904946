import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import isChromatic from 'chromatic/isChromatic';
import {GetServerSidePropsContext} from 'next';
import cookie from 'next/dist/compiled/cookie';

import {
  CreateFulfillmentDetailDocument,
  FulfillmentDetailAddressInput,
  FulfillmentDetailStrategy,
  GeocodedAddressResult,
} from '@/graphql/types';

// give Chromatic and Storybook a different cookie name so it doesn't conflict when swapping between Storybook and local dev
export const GLOBAL_FD_COOKIE =
  isChromatic() || process.env.STORYBOOK === 'true'
    ? 'global_fulfillment_detail_test_id'
    : 'global_fulfillment_detail_id';

export const setFulfillmentDetailCookie = (
  ctx: GetServerSidePropsContext,
  fulfillmentDetailId: string,
) => {
  if (!ctx?.res || !fulfillmentDetailId) return;

  const rawCookieHeader = ctx.res.getHeader('Set-Cookie');
  const resCookies = rawCookieHeader
    ? Array.isArray(rawCookieHeader)
      ? [...rawCookieHeader]
      : [rawCookieHeader as string]
    : [];
  resCookies.push(cookie.serialize(GLOBAL_FD_COOKIE, fulfillmentDetailId, {path: '/'}));
  ctx?.res.setHeader('Set-Cookie', resCookies);
};

export const getFulfillmentDetailFromCookie = (ctx: GetServerSidePropsContext) => {
  const fulfillmentDetailId = ctx?.req?.cookies?.[GLOBAL_FD_COOKIE] ?? null;

  return {
    includeFulfillmentDetailId: fulfillmentDetailId !== null,
    fulfillmentDetailId: fulfillmentDetailId ?? '',
  };
};

export const createFulfillmentDetailCookie = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  ctx: GetServerSidePropsContext,
  address: GeocodedAddressResult | null | undefined,
) => {
  const addressInput: FulfillmentDetailAddressInput = massageFulfillmentDetailAddress(address);

  const {data} = await apolloClient.mutate({
    mutation: CreateFulfillmentDetailDocument,
    variables: {
      input: {
        address: addressInput,
        strategy: FulfillmentDetailStrategy.Delivery,
      },
    },
    errorPolicy: 'all',
  });

  const fulfillmentDetailId = data?.fulfillmentDetailCreate?.fulfillmentDetail?.id;

  if (fulfillmentDetailId) {
    setFulfillmentDetailCookie(ctx, fulfillmentDetailId);
  }
};

export const massageFulfillmentDetailAddress = (
  address: GeocodedAddressResult | null | undefined,
): FulfillmentDetailAddressInput => {
  /// if a recommendedDefaultAddress does not exist, default the FD to Boston
  if (!address) {
    // TODO 1: On a catering page, default to caterer's city/state/zip
    // TODO 2: Log that we are defaulting to Boston
    return {
      street1: null,
      city: 'Boston',
      state: 'MA',
      zip: null,
    };
  }

  const {street, __typename, ...rest} = address;

  return {
    street1: street,
    ...rest,
  };
};
