import {type FC, type PropsWithChildren, ReactNode, useMemo} from 'react';
import {ApolloClient, ApolloProvider as RealApolloProvider, gql} from '@apollo/client';
import {action} from '@storybook/addon-actions';
import {expect, fn as mockFn, waitFor} from '@storybook/test';

import createApolloCache from '@/apollo/createApolloCache';
import {IdentityContext} from '@/components/IdentityProvider';
import {RedirectionContext} from '@/components/RedirectionProvider/RedirectionProvider';
import {getFulfillmentDetailFragment} from './fixtures/getFulfillmentDetail';

export const redirect = mockFn((...args) => action('redirect')(...args));

type RedirectionProviderProps = {
  children: ReactNode;
};

export const RedirectionProvider = ({children}: RedirectionProviderProps) => {
  return <RedirectionContext.Provider value={redirect}>{children}</RedirectionContext.Provider>;
};

export const tracker = {
  track: mockFn((...args) => {
    action('tracker.track')(...args);
    return Promise.resolve(true);
  }),
  trackExposure: mockFn((...args) => {
    action('tracker.trackExposure')(...args);
  }),
  trackClick: mockFn((...args) => {
    action('tracker.trackClick')(...args);
  }),
  trackPageView: mockFn((...args) => {
    action('tracker.trackPageView')(...args);
  }),
  trackIdentify: mockFn((...args) => {
    action('tracker.trackIdentify')(...args);
  }),
  rudderTrack: mockFn((...args) => {
    action('tracker.rudderTrack')(...args);
  }),
  rudderTrackConversion: mockFn((...args) => {
    action('tracker.rudderTrackConversion')(...args);
  }),
} as const;

export const geolocation = {
  getCurrentPosition: (handleGeolocationSuccess: (position: GeolocationPosition) => void) =>
    handleGeolocationSuccess({coords: {latitude: 0, longitude: 0}} as GeolocationPosition),
} as const;

Object.defineProperty(tracker.track, 'name', {value: 'tracker.track', writable: false});
Object.defineProperty(tracker.trackExposure, 'name', {
  value: 'tracker.trackExposure',
  writable: false,
});
Object.defineProperty(tracker.trackClick, 'name', {value: 'tracker.trackClick', writable: false});
Object.defineProperty(tracker.trackPageView, 'name', {
  value: 'tracker.trackPageView',
  writable: false,
});
Object.defineProperty(tracker.trackIdentify, 'name', {
  value: 'tracker.trackIdentify',
  writable: false,
});

export const ApolloProvider: FC<PropsWithChildren<{ignoreCache?: boolean}>> = ({
  children,
  ignoreCache = true,
}) => {
  const client = useMemo(
    () =>
      new ApolloClient({
        uri: '/graphql',
        cache: createApolloCache(),
        defaultOptions: ignoreCache
          ? {
              watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
              },
            }
          : {},
      }),
    [ignoreCache],
  );

  // prepare storybook to always load a fulfillment detail in the cache
  client.writeFragment({
    id: 'FulfillmentDetail:fulfillment-detail-test-id-1',
    fragment: gql`
      fragment AppBarFulfillmentDetailFragment on FulfillmentDetail {
        id
        address {
          id
          name
          street
          city
          state
          zip
          latitude
          longitude
          fullAddress
        }
        editable
        eventLocalTime
        eventAt
        eventName
        eventOn
        headcount
        strategy
        __typename
      }
    `,
    data: {
      ...getFulfillmentDetailFragment(),
    },
  });

  return <RealApolloProvider client={client}>{children}</RealApolloProvider>;
};

export const GuestIdentityProvider: FC<PropsWithChildren<unknown>> = ({children}) => {
  const identity = useMemo(
    () =>
      ({
        data: {
          currentUserTrackingId: '1',
          currentUserHashedTrackingId: '1',
          identity: {
            id: '1',
            isEligibleForPricingFeeExperiment: false,
            __typename: 'MarketplaceIdentity',
          },
          me: null,
          __typename: 'Query',
        },
        isAdmin: false,
        isLoggedIn: false,
        loading: false,
      }) as const,
    [],
  );

  return <IdentityContext.Provider value={identity}>{children}</IdentityContext.Provider>;
};

export const scrollToElement = async (
  elementFn: () => HTMLElement | null,
  timeout?: number,
  scrollProps?: ScrollIntoViewOptions,
) => {
  await waitFor(
    () => {
      const element = elementFn();
      expect(element).toBeInTheDocument();
    },
    {timeout: timeout || 5000},
  );

  await waitFor(
    () => {
      const element = elementFn();
      if (element) element.scrollIntoView({block: 'center', inline: 'nearest', ...scrollProps});
    },
    {timeout: timeout || 5000},
  );
};
