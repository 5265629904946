import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export const LibertyWebchatContext = createContext<{
  webchatEnabled: boolean;
  toggleWebchatVisibility: () => void;
  isWebchatWidgetOpen: boolean;
  iframeEl: React.MutableRefObject<HTMLIFrameElement | null>;
}>({
  webchatEnabled: false,
  toggleWebchatVisibility() {},
  isWebchatWidgetOpen: false,
  iframeEl: {current: null},
});

export const useLibertyWebchat = () => useContext(LibertyWebchatContext);

type LibertyWebchatMessage = {action: 'afterToggleChatVisibility'; isEntryPointExpanded: boolean};

type LibertyWebchatProviderProps = React.PropsWithChildren<{
  webchatEnabled?: boolean;
}>;

const LibertyWebchatProvider: React.FC<LibertyWebchatProviderProps> = ({
  webchatEnabled = false,
  children,
}) => {
  const iframeEl = useRef<HTMLIFrameElement | null>(null);
  const toggleWebchatVisibility = useCallback(() => {
    iframeEl?.current?.contentWindow?.postMessage(
      {
        action: 'ToggleChatVisibility',
      },
      '*',
    );
  }, []);
  const [isWebchatWidgetOpen, setIsWebchatWidgetOpen] = useState(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<LibertyWebchatMessage>) => {
      if (event.origin && event.data?.action === 'afterToggleChatVisibility') {
        setIsWebchatWidgetOpen(event.data.isEntryPointExpanded);
      }
    };

    window.addEventListener('message', handleMessage, {passive: true});
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const providerValue = useMemo(
    () => ({webchatEnabled, toggleWebchatVisibility, isWebchatWidgetOpen, iframeEl}),
    [webchatEnabled, toggleWebchatVisibility, isWebchatWidgetOpen, iframeEl],
  );

  return (
    <LibertyWebchatContext.Provider value={providerValue}>
      {children}
    </LibertyWebchatContext.Provider>
  );
};

export default LibertyWebchatProvider;
