import {type ApolloClient, type NormalizedCache, type NormalizedCacheObject} from '@apollo/client';
import {type GetServerSidePropsContext} from 'next';

import createApolloClient from './createApolloClient';

let apolloClient: ApolloClient<NormalizedCacheObject>;

type InitializeApolloProps = {
  ctx?: GetServerSidePropsContext | null;
  initialState?: NormalizedCacheObject | null;
};

const initializeApollo = ({
  initialState = null,
  ctx,
}: InitializeApolloProps): ApolloClient<NormalizedCacheObject> => {
  const client = apolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    Object.keys(initialState).forEach(dataId => {
      if (initialState[dataId] == null) return;
      (client.cache as unknown as {data: NormalizedCache}).data.merge(dataId, initialState[dataId]);
    });
  }
  // For SSG, SSR, and testing always create a new Apollo Client
  if (typeof window === 'undefined' || process.env.NODE_ENV === 'test') return client;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = client;

  return client;
};

export default initializeApollo;
