import {ApolloLink, type NormalizedCacheObject} from '@apollo/client';
import {ApolloClient, from} from '@apollo/client';
import {type GetServerSidePropsContext} from 'next';

import createHeaderLink from './links/createHeaderLink';
import createHttpLink, {uri} from './links/createHttpLink';
import createRetryLink from './links/createRetryLink';
import createSentryLink from './links/createSentryLink';
import createApolloCache from './createApolloCache';

let createCookieForwardingLink: (ctx?: GetServerSidePropsContext | null) => ApolloLink;
if (typeof window === 'undefined')
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  createCookieForwardingLink = require('./links/createCookieForwardingLink').default;

const createApolloClient = (
  ctx?: GetServerSidePropsContext | null,
): ApolloClient<NormalizedCacheObject> => {
  const namedLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      uri: operation.operationName ? `${uri}/${operation.operationName}` : uri,
    }));
    return forward ? forward(operation) : null;
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from(
      [
        process.env.NODE_ENV !== 'test' && createSentryLink(),
        process.env.NODE_ENV !== 'test' && createRetryLink(),
        createHeaderLink(ctx?.req),
        typeof window === 'undefined' && ctx && createCookieForwardingLink(ctx),
        process.env.NODE_ENV === 'development' && namedLink,
        createHttpLink(),
      ].filter(link => !!link),
    ),
    cache: createApolloCache(),
    name: 'store-next',
    version: process.env.CONFIG_BUILD_ID,
    connectToDevTools: typeof window !== 'undefined' && __DEV__,
  });
};

export default createApolloClient;
