import {useEffect} from 'react';
import {getCurrentScope} from '@sentry/nextjs';

import gtm from '@/utils/gtm';

const useTrackingIdNotifier = (trackingId?: string, hashedTrackingId?: string): void => {
  useEffect(() => {
    if (trackingId) {
      gtm.dataLayer({user_tracking_id: trackingId});
      gtm.dataLayer({user_tracking_hash: hashedTrackingId});
      getCurrentScope().setUser({id: trackingId});
    }
  }, [trackingId, hashedTrackingId]);
};

export default useTrackingIdNotifier;
