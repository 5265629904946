/* eslint-disable @typescript-eslint/consistent-type-definitions */
import type React from 'react';
import {
  EzGlobalStyles,
  EzProvider,
  EzThemeProvider,
  stitchesMarketplaceTheme,
  themes,
} from '@ezcater/recipe';
import {rgba, shade} from 'polished';

import appBorderRadius from './appBorderRadius';
import appColors from './appColors';
import appFonts from './appFonts';

declare module '@mui/material/styles/createPalette' {
  type AppColors = typeof appColors;
  interface Palette {
    appColors?: AppColors;
  }

  interface PaletteOptions {
    appColors?: Partial<AppColors>;
  }
}

declare module '@mui/material/styles/createTypography' {
  type AppFonts = typeof appFonts;

  interface FontStyle {
    appFonts?: AppFonts;
  }
}

declare module '@mui/material/styles/createTheme' {
  type AppBorderRadius = typeof appBorderRadius;

  interface Theme {
    corners?: AppBorderRadius;
  }

  interface ThemeOptions {
    corners?: Partial<AppBorderRadius>;
  }
}

const catererColors = {
  // these are from defaultCatererColors.ts
  primary: appColors.ezGreen,
  shadedPrimary: appColors.ezGreen500,
  secondary: appColors.blue,
  shadedSecondary: shade(0.1, '#3a64df'),
  navItem: appColors.ezGreen,
  eventNameNavItem: appColors.blackOlive,
  primaryAccent: appColors.ezGreen,
  secondaryAccent: appColors.ezGold,
  highlight: rgba(appColors.orange, 0.4), // ezOrange
  reviewQuantitiesQuestion: '#e7f4ff', // lightestBlue
  reviewQuantitiesLink: appColors.blue,
  availableNotification: '#88bb40',
  menuItemName: appColors.blue,
  menuItemIndividuallyPackagedText: '#666974',
  menuItemIndividuallyPackagedBackground: '#f0f2f7',
  cart: '#fff9d9', // lightYellow
  shadedCart: '#e6e0c3',
  cartSeparator: shade(0.1, '#fff9d9'), // lightYellow
  reviewStar: '#f4d689', // yellow500
  checkbox: appColors.ezGreen,
  tipActive: appColors.ezGreen,
  tipBackground: appColors.primary90, // green90
  categoryLink: appColors.blue,
  shadedCategoryLink: shade(0.1, '#3a64df'),
  cardHoverBorder: appColors.gray140,
};

const {createTheme, ezMarketplaceTheme} = themes;

const storeTheme = createTheme(ezMarketplaceTheme, {
  palette: {
    appColors,
    catererColors,
    common: {
      catererColorCheckbox: catererColors.checkbox,
    },
  },

  typography: {appFonts},
  corners: {...appBorderRadius},
});

const StylesProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => (
  <EzThemeProvider theme={storeTheme}>
    <EzProvider theme={stitchesMarketplaceTheme}>
      <EzGlobalStyles />
      {children}
    </EzProvider>
  </EzThemeProvider>
);

export default StylesProvider;
