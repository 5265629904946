import {createContext, useContext, useMemo} from 'react';

import FeatureFlags from '@/FeatureFlags';
import {type PromoCodeQuery, usePromoCodeQuery} from '@/graphql/types';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIsOnMenuPage from '@/hooks/useIsOnMenuPage';
import type RequiredPath from '@/utils/RequiredPath';

type Promo = RequiredPath<PromoCodeQuery, ['me', 'consumerAccount', 'assignedPromoCode', 'promo']>;

export const EzRewardsPromosContext = createContext<{promo: Promo | null}>({promo: null});

export const useEzRewardsUserAssignedPromo = () => useContext(EzRewardsPromosContext);

type EzRewardsPromosProviderProps = React.PropsWithChildren<unknown>;

const EzRewardsPromosProvider: React.FC<EzRewardsPromosProviderProps> = ({children}) => {
  const dealiciousPromoEnabled = useFeatureFlag(FeatureFlags.DealiciousPromo, {
    silent: true,
  });
  const {isOnMenuPage} = useIsOnMenuPage();

  // This is a temporary solution during Dealicious 2.0 (Oct 7 - Oct 18)
  const OnMenuPageDuringDealicious = isOnMenuPage && dealiciousPromoEnabled;

  const {data} = usePromoCodeQuery({
    errorPolicy: 'all',
  });

  const promo = useMemo(() => {
    const globalPromo = data?.globalPromoCode;
    const assignedPromo = data?.me?.consumerAccount?.assignedPromoCode;

    if (globalPromo && globalPromo?.promo?.active && !OnMenuPageDuringDealicious)
      return globalPromo.promo;
    if (assignedPromo && assignedPromo?.promo?.active) return assignedPromo.promo;

    return null;
  }, [data, OnMenuPageDuringDealicious]);

  return (
    <EzRewardsPromosContext.Provider value={{promo}}>{children}</EzRewardsPromosContext.Provider>
  );
};

export default EzRewardsPromosProvider;
