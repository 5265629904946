import {type IdentityContextType} from '@/components/IdentityProvider';

export const signedInIdentity: IdentityContextType = {
  data: {
    currentUserTrackingId: '1',
    currentUserHashedTrackingId: '1',
    identity: {
      id: '1',
      isEligibleForPricingFeeExperiment: false,
      __typename: 'MarketplaceIdentity',
    },
    me: {
      id: 'me-1',
      consumerAccount: {
        id: 'ca-1',
        email: 'test@testing.com',
        shouldLoadFullstory: true,
        canAccessCorpPortal: true,
        canAccessConcur: true,
        identityId: 'i-2',
        canEarnEzRewards: true,
        canEarnReferralRewards: true,
        enrolledInEzRewards: true,
        canCollectOwnEzRewards: true,
        canPayWithEzRewards: true,
        ezRewardsAccount: {
          __typename: 'EzRewardsAccount',
          id: '1',
          balance: 10389,
        },
        __typename: 'ConsumerAccount',
      },
    },
    __typename: 'Query',
  },
  isAdmin: false,
  isLoggedIn: true,
  loading: false,
};
