// not currently exposed from recipe
// https://github.com/ezcater/recipe/blob/61bbe82a7c122af2496d351a4b2b5befd93917fe/src/components/theme.config.ts#L203-L210
export const media = {
  base: '(min-width: 0px)',
  baseToMedium: '(max-width: 767.9375px)',
  baseToLarge: '(max-width: 1060.9375px)',
  baseToXLarge: '(max-width: 1279.9375px)',
  medium: '(min-width: 768px)',
  mediumToLarge: '(min-width: 768px) and (max-width: 1060.9375px)',
  large: '(min-width: 1061px)',
  xlarge: '(min-width: 1280px)',
} as const;

type Media = typeof media;

const recipeMq = <T extends keyof Media>(size: T): `@media ${Media[T]}` => `@media ${media[size]}`;

export default recipeMq;
