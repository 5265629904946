import type React from 'react';
import {createContext, useCallback, useMemo, useState} from 'react';

export const ContactUsModalContext = createContext({
  isOpen: false,
  toggleContactUsModal: () => {},
});

const ContactUsModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleContactUsModal = useCallback(() => {
    setIsOpen(isCurrentlyOpen => !isCurrentlyOpen);
  }, [setIsOpen]);
  const providerValue = useMemo(
    () => ({isOpen, toggleContactUsModal}),
    [isOpen, toggleContactUsModal],
  );

  return (
    <ContactUsModalContext.Provider value={providerValue}>
      {children}
    </ContactUsModalContext.Provider>
  );
};

export default ContactUsModalProvider;
