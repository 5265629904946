import {useRouter} from 'next/router';

import useLibertyWebchatPositioning from '@/hooks/useLibertyWebchatPositioning';
import usePromoToastPositioning from '@/hooks/usePromoToastPositioning';
import EzRewardsPromoToast from '../EzRewardsPromo/EzRewardsPromoToast';
import LibertyWebchatWidget from '../LibertyWebchatWidget';

const FloatingActionButtons: React.FC<unknown> = () => {
  const router = useRouter();
  const isEzOrdering = router.pathname.includes('/catering/pvt/');

  const {libertyWebchatPosition} = useLibertyWebchatPositioning();
  const {bannerPosition} = usePromoToastPositioning();

  if (isEzOrdering) return null;

  return (
    <>
      <LibertyWebchatWidget className={libertyWebchatPosition()} />
      <EzRewardsPromoToast bannerPosition={bannerPosition} />
    </>
  );
};

export default FloatingActionButtons;
