import {DEFAULT_POSITION, DEFAULT_TOAST_POSITION, TOAST_BUTTON_SPACING} from '../styles';
import {FloatingActionToastProps} from '../types';
import FloatingActionButtonWithBadge from './FloatingActionButtonWithBadge';

const FloatingActionToast = ({
  children,
  closedIcon,
  openIcon,
  position = {right: DEFAULT_POSITION, bottom: DEFAULT_POSITION},
  handleClick,
  isOpen,
}: FloatingActionToastProps) => {
  const containerPosition = {
    right: `${position.right}px`,
    bottom: `${position.bottom}px`,
  };

  const toastBottomPosition =
    position.bottom === DEFAULT_POSITION
      ? DEFAULT_TOAST_POSITION
      : position.bottom! + TOAST_BUTTON_SPACING;

  const toastPosition = {
    bottom: `${toastBottomPosition}px`,
    right: `${position.right}px`,
  };

  return (
    <>
      {isOpen && (
        <div className="fixed z-50" style={toastPosition}>
          {children}
        </div>
      )}
      <FloatingActionButtonWithBadge
        handleClick={handleClick}
        isOpen={isOpen}
        closedIcon={closedIcon}
        openIcon={openIcon}
        position={containerPosition}
      />
    </>
  );
};

export default FloatingActionToast;
