import type React from 'react';
import {createContext, useMemo} from 'react';
import {type IFeatureFlagData} from '@ezcater/feature-flags-js/dist/types';

export const FeatureFlagsContext = createContext<IFeatureFlagData>({});

type FeatureFlagsProviderProps = React.PropsWithChildren<{
  cache?: IFeatureFlagData | null;
}>;

const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({cache, children}) => {
  const providerCache = useMemo(() => cache || {}, [cache]);

  return (
    <FeatureFlagsContext.Provider value={providerCache}>{children}</FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;
