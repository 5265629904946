import {useContext, useEffect} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import {useRouter} from 'next/router';

import {FeatureFlagsContext} from '@/components/RootProvider';
import type FeatureFlags from '@/FeatureFlags';
import {sanitizeDatadogFeatureFlagName} from '@/utils/datadog';

type UseFeatureFlagOptions = {
  enabled?: boolean;
  silent?: boolean;
};

let alreadyWarned: Record<string, boolean> = {};
const staticPaths = new Set(['/404', '/500']);
const useFeatureFlagDevLogger: (featureFlagName: string | undefined, wasLoaded: boolean) => void =
  __DEV__
    ? (featureFlagName, wasLoaded) => {
        const {pathname} = useRouter();
        const key = `${featureFlagName}-${pathname}`;

        if (featureFlagName && !wasLoaded && !alreadyWarned[key] && !staticPaths.has(pathname)) {
          alreadyWarned[key] = true;

          // eslint-disable-next-line no-console
          console.warn(
            `Feature flag "${featureFlagName}" was not loaded for page ${pathname}, did you forget to load it in getServerSideProps?`,
          );
        }
      }
    : () => {};
export const resetWarnings__DO_NOT_USE = () => {
  alreadyWarned = {};
};

const useFeatureFlag = (
  featureFlag: FeatureFlags | undefined,
  {enabled: hookEnabled = true, silent = false}: UseFeatureFlagOptions = {},
): boolean => {
  const cache = useContext(FeatureFlagsContext);
  const enabled = hookEnabled && featureFlag != null;
  const featureFlagValue = enabled ? (cache[featureFlag] ?? false) : false;

  useEffect(() => {
    if (featureFlag)
      datadogRum.addFeatureFlagEvaluation(
        sanitizeDatadogFeatureFlagName(featureFlag),
        featureFlagValue,
      );
  }, [featureFlag, featureFlagValue]);

  useFeatureFlagDevLogger(featureFlag, !enabled || silent || cache[featureFlag] != null);

  return featureFlagValue;
};

export default useFeatureFlag;
