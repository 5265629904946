import {useCallback, useContext, useEffect} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import {useRouter} from 'next/router';

import {ExperimentsContext} from '@/components/RootProvider';
import type Experiments from '@/Experiments';
import experimentsConfig from '@/experimentsConfig';
import {sanitizeDatadogFeatureFlagName} from '@/utils/datadog';
import useFeatureFlag from './useFeatureFlag';
import useTracking from './useTracking';

let alreadyWarned: Record<string, boolean> = {};
const staticPaths = new Set(['/404', '/500']);
const useExperimentDevLogger: (experimentName: string, wasLoaded: boolean) => void = __DEV__
  ? (experimentName, wasLoaded) => {
      const {pathname} = useRouter();
      const key = `${experimentName}-${pathname}`;

      if (!wasLoaded && !alreadyWarned[key] && !staticPaths.has(pathname)) {
        alreadyWarned[key] = true;

        // eslint-disable-next-line no-console
        console.warn(
          `Experiment "${experimentName}" was not loaded for page ${pathname}, did you forget to load it in getServerSideProps?`,
        );
      }
    }
  : () => {};
export const resetWarnings__DO_NOT_USE = () => {
  alreadyWarned = {};
};

const useExperiment = (experimentName: Experiments) => {
  const tracker = useTracking();
  const resolvedExperiments = useContext(ExperimentsContext);

  // if there is a feature flag assigned to the experiment, check it
  const featureFlagName = experimentsConfig[experimentName];
  const hasFeatureFlagAssigned = Boolean(featureFlagName);
  const featureFlagEnabled = useFeatureFlag(featureFlagName, {enabled: hasFeatureFlagAssigned});

  // we will use the real variant if the feature flag is enabled, or if there is no feature flag
  const useActualVariant = hasFeatureFlagAssigned ? featureFlagEnabled : true;

  // if there is an experiment loaded and we are able to use the actual variant, use it
  // otherwise, fallback to the control group
  const {variant, isFallback} =
    resolvedExperiments[experimentName] && useActualVariant
      ? resolvedExperiments[experimentName]
      : {
          variant: 'control',
          isFallback: true,
        };

  const trackExposure = useCallback(
    (forceControl?: boolean) => {
      const currentVariant = forceControl ? 'control' : variant;
      if (!isFallback) tracker.trackExposure(experimentName, currentVariant);
    },
    [variant, isFallback, tracker, experimentName],
  );

  useEffect(() => {
    datadogRum.addFeatureFlagEvaluation(sanitizeDatadogFeatureFlagName(experimentName), variant);
  }, [experimentName, variant]);

  useExperimentDevLogger(experimentName, resolvedExperiments[experimentName] != null);

  return {
    variant,
    isFallback,
    trackExposure,
  };
};

export default useExperiment;
