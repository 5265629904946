import {useRouter} from 'next/router';

const useIsOnMenuPage = () => {
  const router = useRouter();
  const cartId = router.query?.cartId;
  const isOnMenuPage =
    router.pathname === '/catering/[catererUrlId]' ||
    router.pathname === '/catering/[catererUrlId]/[[...date]]' ||
    router.pathname === '/catering/eater/[catererUrlId]/[[...date]]' ||
    router.pathname === '/catering/pvt/[catererUrlId]' ||
    router.pathname === '/catering/pvt/[catererUrlId]/[[...date]]';

  return {cartId, isOnMenuPage};
};

export default useIsOnMenuPage;
