import {createContext, useContext} from 'react';
import {useCookies} from 'react-cookie';
import {FetchResult, useFragment, useMutation} from '@apollo/client';
import isChromatic from 'chromatic/isChromatic';
import {getFulfillmentDetail} from 'stories/utils';
import invariant from 'tiny-invariant';

import Experiments from '@/Experiments';
import FeatureFlags from '@/FeatureFlags';
import {
  AppBarFulfillmentDetailFragment,
  AppBarFulfillmentDetailFragmentDoc,
  AppBarFulfillmentDetailUpdateMutationDocument,
  FulfillmentDetailUpdateInput,
} from '@/graphql/types';
import useExperiment from '@/hooks/useExperiment';
import {GLOBAL_FD_COOKIE} from '@/pageApi/manageFulfillmentDetailCookie';

export type GlobalFulfillmentDetailMutationContextType = {
  updateFulfillmentDetail: (input: FulfillmentDetailUpdateInput) => Promise<FetchResult>;
};

export const GlobalFulfillmentDetailContext = createContext<AppBarFulfillmentDetailFragment | null>(
  null,
);

export const GlobalFulfillmentDetailMutationContext =
  createContext<GlobalFulfillmentDetailMutationContextType | null>(null);

export const useGlobalFulfillmentDetail = () => useContext(GlobalFulfillmentDetailContext);
export const useGlobalFulfillmentDetailMutation =
  (): GlobalFulfillmentDetailMutationContextType => {
    const contextValue = useContext(GlobalFulfillmentDetailMutationContext);

    invariant(
      contextValue != null,
      'updateFulfillmentDetail was called, but the GlobalFulfillmentDetailMutationProvider was not used',
    );

    return contextValue;
  };

const GlobalFulfillmentDetailProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {variant: ez460variant} = useExperiment(Experiments.NewAppBar);
  // TODO: Remove isAppBarEnabled flag when rolling out the refactored app bar
  const isNewAppBarEnabled = FeatureFlags.NewAppBar && ez460variant === 'treatment';
  const [cookies] = useCookies([GLOBAL_FD_COOKIE]);
  const [appBarFulfillmentDetailMutation] = useMutation(
    AppBarFulfillmentDetailUpdateMutationDocument,
  );

  // if Chromatic is running, it struggles to get the cookie and link it to the fulfillment detail stored in this fragment. For now, we use the fulfillment detail from our test data directly when dealing with Chromatic snapshots.
  const linkedFDId = isChromatic() ? getFulfillmentDetail().id : cookies[GLOBAL_FD_COOKIE];

  const {data: fulfillmentDetailData, complete} = useFragment({
    fragment: AppBarFulfillmentDetailFragmentDoc,
    from: {
      // FIXME: https://github.com/apollographql/apollo-client/issues/11600
      // we use `false` as a fallback to make updates work in React
      id: linkedFDId || false,
      __typename: 'FulfillmentDetail',
    },
    canonizeResults: true,
  });

  const fdProviderValue =
    complete && fulfillmentDetailData?.id && isNewAppBarEnabled ? fulfillmentDetailData : null;

  const updateFulfillmentDetail = (input: FulfillmentDetailUpdateInput): Promise<FetchResult> => {
    return appBarFulfillmentDetailMutation({
      variables: {
        id: fdProviderValue.id,
        input,
      },
    });
  };

  return (
    <GlobalFulfillmentDetailContext.Provider value={fdProviderValue}>
      <GlobalFulfillmentDetailMutationContext.Provider value={{updateFulfillmentDetail}}>
        {children}
      </GlobalFulfillmentDetailMutationContext.Provider>
    </GlobalFulfillmentDetailContext.Provider>
  );
};

export default GlobalFulfillmentDetailProvider;
