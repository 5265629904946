export const DEFAULT_POSITION = 16;
export const DEFAULT_TOAST_POSITION = 80;
export const TOAST_BUTTON_SPACING = DEFAULT_TOAST_POSITION - DEFAULT_POSITION;

export const REWARDS_BANNER_OPEN_OFFSET = 60;

const BASE_POSITION = 16;
export const DESKTOP_TABLET_RIGHT_POSITION = BASE_POSITION;
export const DESKTOP_TABLET_BOTTOM_POSITION = BASE_POSITION;
export const DESKTOP_TABLET_RIGHT_POSITION_MENU_PAGE = 345;
export const MOBILE_RIGHT_POSITION = BASE_POSITION;
export const MOBILE_BOTTOM_POSITION = BASE_POSITION;
export const MOBILE_MENU_BOTTOM_POSITION = BASE_POSITION + 96;
