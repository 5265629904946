import {gtm} from '@/utils';
import {GoogleAnalyticsEventEnum} from '@/utils/googleAnalyticsEvents';

// this must be called *before* the chat is opened
const trackChatStarted = () => {
  const libertyChatModal = document.getElementById('liberty-webchat-iframe');
  const isPresent = !!libertyChatModal?.getBoundingClientRect()?.height;
  const isNotAlreadyOpen = libertyChatModal?.getBoundingClientRect()?.height !== 650;
  if (isPresent && isNotAlreadyOpen) {
    gtm.dataLayer({event: GoogleAnalyticsEventEnum.chatStart});
  }
};

export default trackChatStarted;
