import {type ApolloLink} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';

const createRetryLink = (): ApolloLink =>
  new RetryLink({
    attempts: {
      retryIf: error =>
        !!error &&
        error?.message !== 'Failed to fetch' &&
        error?.statusCode !== 500 &&
        error?.statusCode !== 400,
      max: 3,
    },
  });

export default createRetryLink;
