import {useEffect, useState} from 'react';
import {Icon} from '@ezcater/tapas';
import {faAngleDown, faGift} from '@fortawesome/pro-regular-svg-icons';

import useTracking from '@/hooks/useTracking';
import {safeSessionStorage} from '@/utils/storage';
import {useEzRewardsUserAssignedPromo} from '../EzRewardsPromosProvider';
import {useLibertyWebchat} from '../LibertyWebchatProvider';
import FloatingActionToast from './Components/FloatingActionToast';
import ToastContent from './Components/ToastContent';
import {
  CLOSE_ICON_ID,
  CLOSED,
  OPEN,
  OPEN_ICON_ID,
  REWARDS_BANNER_CLOSED_TRACKING,
  REWARDS_BANNER_OPENED_TRACKING,
  REWARDS_BANNER_SHOWN_TRACKING,
  REWARDS_BANNER_VIEWED_ITEM,
  REWARDS_BANNER_VISIBILITY_PREFERENCE_ITEM,
  STORAGE_ITEM_TRUTHY,
} from './constants';

export type EzRewardsPromoToastProps = {bannerPosition: object};

const EzRewardsPromoToast: React.FC<EzRewardsPromoToastProps> = ({bannerPosition}) => {
  const {track, trackClick} = useTracking();
  const {promo} = useEzRewardsUserAssignedPromo();
  const {isWebchatWidgetOpen, toggleWebchatVisibility} = useLibertyWebchat();
  const [isToastOpen, setIsToastOpen] = useState(true);

  useEffect(() => {
    if (isWebchatWidgetOpen) setIsToastOpen(false);
  }, [isWebchatWidgetOpen]);

  const setSessionBannerViewed = () => {
    safeSessionStorage.setItem(REWARDS_BANNER_VIEWED_ITEM, STORAGE_ITEM_TRUTHY);
  };

  const trackClose = () => {
    trackClick(REWARDS_BANNER_CLOSED_TRACKING);
  };

  const trackOpen = () => {
    trackClick(REWARDS_BANNER_OPENED_TRACKING);
  };

  const setSessionVisibilityPreference = (preference: string) => {
    safeSessionStorage.setItem(REWARDS_BANNER_VISIBILITY_PREFERENCE_ITEM, preference);
  };

  const handleOpen = () => {
    trackOpen();
    setIsToastOpen(true);
    setSessionVisibilityPreference(OPEN);
  };

  const handleFirstCloseInSession = () => {
    setIsToastOpen(false);
    trackClose();
    setSessionVisibilityPreference(CLOSED);
    setSessionBannerViewed();
  };

  const handleSubsequentCloseInSession = () => {
    setIsToastOpen(false);
    trackClose();
    setSessionVisibilityPreference(CLOSED);
  };

  const handleClose = () => {
    const hasRewardsBannerBeenViewed =
      safeSessionStorage.getItem(REWARDS_BANNER_VIEWED_ITEM) === STORAGE_ITEM_TRUTHY;
    if (hasRewardsBannerBeenViewed) {
      handleSubsequentCloseInSession();
    } else {
      handleFirstCloseInSession();
    }

    setIsToastOpen(false);
  };

  const handleClick = () => {
    if (isToastOpen) {
      handleClose();
    } else {
      if (isWebchatWidgetOpen) {
        toggleWebchatVisibility();
      }
      handleOpen();
    }
  };
  const [hasRewardsBannerBeenViewedInThisSession, setHasRewardsBannerBeenViewedInThisSession] =
    useState(false);

  useEffect(() => {
    if (safeSessionStorage.getItem(REWARDS_BANNER_VISIBILITY_PREFERENCE_ITEM) === CLOSED) {
      setIsToastOpen(false);
    } else {
      setIsToastOpen(true);
    }
  }, []);

  useEffect(() => {
    if (
      safeSessionStorage.getItem(REWARDS_BANNER_VISIBILITY_PREFERENCE_ITEM) === STORAGE_ITEM_TRUTHY
    ) {
      setHasRewardsBannerBeenViewedInThisSession(true);
    } else {
      setHasRewardsBannerBeenViewedInThisSession(false);
    }
  }, []);

  useEffect(() => {
    if (promo && !hasRewardsBannerBeenViewedInThisSession) {
      track(REWARDS_BANNER_SHOWN_TRACKING, {
        url: window.location.href,
        page: window.location.pathname,
        user_agent: window.navigator.userAgent,
        misc_json: JSON.stringify({
          promo_id: promo.id,
        }),
      });
      setHasRewardsBannerBeenViewedInThisSession(true);
    } else {
      safeSessionStorage.removeItem(REWARDS_BANNER_VIEWED_ITEM);
    }
  }, [promo, hasRewardsBannerBeenViewedInThisSession, track]);

  if (!promo) return null;

  const {description, finePrint, termsAndConditionsUrl, title} = promo;

  return (
    <FloatingActionToast
      closedIcon={
        <Icon icon={faGift} size="medium" className="flex-none" data-testid={OPEN_ICON_ID} />
      }
      openIcon={
        <Icon icon={faAngleDown} size="medium" className="flex-none" data-testid={CLOSE_ICON_ID} />
      }
      position={bannerPosition}
      handleClick={handleClick}
      isOpen={isToastOpen}
    >
      <ToastContent
        title={title}
        description={description}
        finePrint={finePrint}
        termsAndConditionsUrl={termsAndConditionsUrl}
      />
    </FloatingActionToast>
  );
};

export default EzRewardsPromoToast;
