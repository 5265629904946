import type React from 'react';
import {Button} from '@ezcater/tapas';
import {useFormikContext} from 'formik';

const SubmitButton: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {isSubmitting} = useFormikContext();

  return (
    <Button className="w-full tablet:w-auto" type="submit" loading={isSubmitting}>
      {children}
    </Button>
  );
};

export default SubmitButton;
