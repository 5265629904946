// @ts-strict-ignore
import {memo, useContext, useEffect, useState} from 'react';
import {Alert, Modal} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';

import ALERT_TYPES from './alertTypes';
import ContactUsForm from './ContactUsForm';
import ContactUsInfo from './ContactUsInfo';
import {ContactUsModalContext} from './ContactUsModalProvider';

type SnackbarState = {
  text: string;
  alertType: 'success' | 'error' | 'warning' | 'info';
};

const ContactUsModal = () => {
  const {t} = useTranslation('common');
  const {isOpen, toggleContactUsModal} = useContext(ContactUsModalContext);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    text: '',
    alertType: ALERT_TYPES.SUCCESS as 'success',
  });
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const showAlert = alertType => {
    if (alertType === ALERT_TYPES.SUCCESS) {
      setSnackbar({
        text: t('components.ContactUs.modal.confirmation'),
        alertType: ALERT_TYPES.SUCCESS as 'success',
      });
    } else {
      setSnackbar({
        text: t('components.ContactUs.modal.failure'),
        alertType: ALERT_TYPES.ERROR as 'error',
      });
    }
    setIsAlertVisible(true);
  };

  useEffect(() => {
    if (!isAlertVisible) return undefined;

    const timer = setTimeout(() => setIsAlertVisible(false), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [isAlertVisible]);

  return (
    <>
      <Modal
        id="contact-us-modal"
        onClose={toggleContactUsModal}
        open={isOpen}
        size="large"
        slotProps={{content: {className: 'p-0'}}}
      >
        <div className="flex flex-col tablet:flex-row">
          <ContactUsInfo toggleContactUsModal={toggleContactUsModal} />
          <ContactUsForm showAlert={showAlert} toggleContactUsModal={toggleContactUsModal} />
        </div>
      </Modal>
      {isAlertVisible && (
        <div className="fixed inset-x-2 bottom-2 z-1350 flex items-center justify-end tablet:bottom-6 tablet:left-auto tablet:right-6">
          <Alert variant={snackbar.alertType} onDismiss={() => setIsAlertVisible(false)}>
            {snackbar.text}
          </Alert>
        </div>
      )}
    </>
  );
};

export default memo(ContactUsModal);
