import {createContext, useMemo} from 'react';
import invariant from 'tiny-invariant';

export type GeolocationContextType = {
  getCurrentPosition: Geolocation['getCurrentPosition'];
};

export const GeolocationContext = createContext<GeolocationContextType | null>(null);

const GeolocationProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const providerValue: GeolocationContextType = useMemo(
    () => ({
      getCurrentPosition: (handleSuccess, handleError) => {
        invariant(
          navigator != null,
          'Cannot use `getCurrentPosition` outside of a browser runtime',
        );
        navigator?.geolocation?.getCurrentPosition(handleSuccess, handleError);
      },
    }),
    [],
  );

  return (
    <GeolocationContext.Provider value={providerValue}>{children}</GeolocationContext.Provider>
  );
};

export default GeolocationProvider;
