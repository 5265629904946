import {useContext} from 'react';

import {FlashMessageContext, type Message} from '@/components/FlashMessage/FlashMessageProvider';

export type FlashMessagePayload = {
  message: Message | null;
  setMessage: (newMessage: Message) => void;
};

const useFlashMessage = (): FlashMessagePayload => useContext(FlashMessageContext);

export default useFlashMessage;
