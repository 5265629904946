import createCache, {type EmotionCache} from '@emotion/cache';

const speedy =
  typeof window === 'undefined' || Boolean(window.CSS?.supports?.('color', 'var(--a)'));
const nonce =
  typeof window === 'undefined'
    ? undefined
    : (() => {
        const node = document.querySelector('[nonce]');
        return (node as {nonce?: string})?.nonce || node?.getAttribute('nonce') || undefined;
      })();

const emotionCache: EmotionCache = createCache({
  key: 'ez',
  speedy,
  nonce,
});

export default emotionCache;
