import {IdentityContextType} from '@/components/IdentityProvider';

export const signedInCorpParentAdminIdentity: IdentityContextType = {
  data: {
    currentUserTrackingId: '3',
    currentUserHashedTrackingId: '3',
    identity: {
      id: '3',
      isEligibleForPricingFeeExperiment: false,
      __typename: 'MarketplaceIdentity',
    },
    me: {
      id: 'me-3',
      consumerAccount: {
        id: 'ca-3',
        email: 'test@testing.com',
        shouldLoadFullstory: true,
        canCollectOwnEzRewards: true,
        canAccessCorpPortal: true,
        canAccessConcur: true,
        identityId: 'i-4',
        corporateAccount: {
          __typename: 'CorporateAccount',
          id: '12345',
          isParent: true,
          name: 'Parent Corp',
          isCharitable: false,
        },
        ownedCorporateAccounts: [
          {
            __typename: 'CorpAccount',
            id: '12345',
            isParent: false,
            name: 'Child Corp',
          },
          {
            __typename: 'CorpAccount',
            id: '45678',
            isParent: true,
            name: 'Parent Corp',
          },
        ],
        __typename: 'ConsumerAccount',
        canEarnEzRewards: true,
        canPayWithEzRewards: true,
      },
    },
  },
  isAdmin: false,
  isLoggedIn: true,
  loading: false,
};
