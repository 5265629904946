import {type ReactElement} from 'react';
import {Icon, Typography} from '@ezcater/tapas';
import {
  faClock,
  faEnvelope,
  faFax,
  faMessage,
  faMobile,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';

import {useLibertyWebchat} from '@/components/LibertyWebchatProvider';
import {gtm} from '@/utils';
import {contactUsClickPayload} from '@/utils/googleAnalyticsEvents';
import trackChatStarted from '@/utils/trackChatStarted';

type ContactUsInfoProps = {
  toggleContactUsModal: () => void;
};

const trackGTMEvent = (contactType: string) => {
  gtm.dataLayer(contactUsClickPayload({contactType}));
};

const ContactUsInfo = ({toggleContactUsModal}: ContactUsInfoProps): ReactElement => {
  const {t} = useTranslation('common');
  const {toggleWebchatVisibility} = useLibertyWebchat();

  const toggleLibertyChat = () => {
    toggleWebchatVisibility();
    toggleContactUsModal();
  };

  return (
    <div className="m-6 tablet:m-12">
      <Typography as="h1" variant="heading-md">
        {t('components.ContactUs.info.title')}
      </Typography>
      <div className="tablet:mb-12">
        <div className="my-6 flex items-center">
          <Icon className="mr-4" icon={faPhone} size="medium" />
          <div>
            <p id="contact-us-modal-phone-label">{t('components.ContactUs.info.call')}</p>
            <a
              aria-labelledby="contact-us-modal-phone-label"
              className="font-bold"
              href="tel:8004881803"
              onClick={() => trackGTMEvent('phone')}
            >
              {t('components.ContactUs.info.callNumber')}
            </a>
          </div>
        </div>
        <div className="my-6 flex items-center">
          <Icon className="mr-4" icon={faMessage} size="medium" />
          <div>
            <p>{t('components.ContactUs.info.chat')}</p>
            <button
              className="font-bold text-blueberry-400"
              onClick={() => {
                trackChatStarted();
                toggleLibertyChat();
                trackGTMEvent('chat');
              }}
              type="button"
            >
              {t('components.ContactUs.info.chatLink')}
            </button>
          </div>
        </div>
        <div className="my-6 flex items-center">
          <Icon className="mr-4" icon={faMobile} size="medium" />
          <div>
            <p id="contact-us-modal-sms-label">{t('components.ContactUs.info.text')}</p>
            <a
              aria-labelledby="contact-us-modal-sms-label"
              className="font-bold"
              href="sms:7813522651"
              onClick={() => trackGTMEvent('text')}
            >
              {t('components.ContactUs.info.textNumber')}
            </a>
          </div>
        </div>
        <div className="my-6 flex items-center">
          <Icon className="mr-4" icon={faEnvelope} size="medium" />
          <div>
            <p id="contact-us-modal-email-label">{t('components.ContactUs.info.email')}</p>
            <a
              aria-labelledby="contact-us-modal-email-label"
              className="font-bold"
              href="mailto:support@ezcater.com"
              onClick={() => trackGTMEvent('email')}
            >
              {t('components.ContactUs.info.emailAddress')}
            </a>
          </div>
        </div>
        <div className="my-6 flex items-center">
          <Icon className="mr-4" icon={faFax} size="medium" />
          <div>
            <p id="contact-us-modal-fax-label">{t('components.ContactUs.info.fax')}</p>
            <a
              aria-labelledby="contact-us-modal-fax-label"
              className="font-bold"
              href="fax:8668471510"
              onClick={() => trackGTMEvent('fax')}
            >
              {t('components.ContactUs.info.faxNumber')}
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <Icon className="mr-4" icon={faClock} size="medium" />
        <span>{t('components.ContactUs.info.availableHours')}</span>
      </div>
    </div>
  );
};

export default ContactUsInfo;
