
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@/styles/app.css';
import '@/styles/fonts';
import 'react-datepicker/dist/react-datepicker.css';
import '../datadogInitialization';
import { type ReactElement } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import { ErrorBoundary } from '@sentry/nextjs';
import { type AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import ezCaterFavicon2023 from '@/assets/images/brand/ezCaterFavicon2023.ico';
import EzRewardsPromosProvider from '@/components/EzRewardsPromosProvider';
import FlashMessage from '@/components/FlashMessage';
import FlashMessageProvider from '@/components/FlashMessage/FlashMessageProvider';
import FloatingActionButtons from '@/components/FloatingActionButtons';
import GeolocationProvider from '@/components/GeolocationProvider';
import GlobalFulfillmentDetailProvider from '@/components/GlobalFulfillmentDetailProvider';
import GlobalScripts from '@/components/GlobalScripts';
import GoogleMapsProvider from '@/components/GoogleMapsProvider';
import IdentityProvider from '@/components/IdentityProvider';
import IntercomProviderWrapper from '@/components/IntercomProviderWrapper';
import LazyMotion from '@/components/LazyMotion';
import LibertyWebchatProvider from '@/components/LibertyWebchatProvider';
import OhCrumbs from '@/components/OhCrumbs';
import OrderingExperienceProvider from '@/components/OrderingExperienceProvider';
import PageReferrerProvider from '@/components/PageReferrerProvider';
import PageTracking from '@/components/PageTracking';
import RedirectionProvider from '@/components/RedirectionProvider';
import RootProvider from '@/components/RootProvider';
import TrackingProvider from '@/components/TrackingProvider';
import useApollo from '@/hooks/useApollo';
import emotionCache from '../emotionCache';
type StoreNextAppProps<P = unknown> = Omit<AppProps<P>, 'pageProps'> & {
    pageProps: StoreNextPageDataProps;
};
const { publicRuntimeConfig } = getConfig();
const StoreNextApp = ({ Component, pageProps: { initialApolloState, featureFlags, experiments, libertyWebchat, pageTrackingProps, referrer, ...pageProps }, }: StoreNextAppProps): ReactElement => {
    const apolloClient = useApollo(initialApolloState);
    return (<ErrorBoundary showDialog={false} fallback={({ error, componentStack }) => (<OhCrumbs allowBrowserRefresh error={error} componentStack={componentStack}/>)}>
      <Head>
        <link rel="icon" type="image/x-icon" href={ezCaterFavicon2023.src}/>
        <link rel="preconnect" href="https://cdnjs.cloudflare.com"/>
        <link rel="dns-prefetch" href="https://cdnjs.cloudflare.com"/>
        {publicRuntimeConfig.googleSiteVerificationCode && (<meta name="google-site-verification" content={publicRuntimeConfig.googleSiteVerificationCode}/>)}
        {publicRuntimeConfig.disableRobotIndexing && (<meta name="robots" content="noindex, nofollow"/>)}
      </Head>
      <LazyMotion>
        <PageReferrerProvider referrer={referrer}>
          <CacheProvider value={emotionCache}>
            <ApolloProvider client={apolloClient}>
              <RootProvider featureFlagsCache={featureFlags} experimentsCache={experiments}>
                <GeolocationProvider>
                  <OrderingExperienceProvider>
                    <IdentityProvider>
                      <TrackingProvider>
                        <FlashMessageProvider>
                          <CookiesProvider defaultSetOptions={{ path: '/' }}>
                            <GlobalFulfillmentDetailProvider>
                              <GoogleMapsProvider>
                                <LibertyWebchatProvider webchatEnabled={Boolean(libertyWebchat?.enabled)}>
                                  <EzRewardsPromosProvider>
                                    <IntercomProviderWrapper>
                                      <FlashMessage />
                                      <GlobalScripts />
                                      <PageTracking pageTrackingProps={pageTrackingProps}/>
                                      <RedirectionProvider>
                                        <div className="relative flex min-h-full min-w-full flex-col">
                                          <Component {...pageProps}/>
                                        </div>
                                      </RedirectionProvider>
                                      <FloatingActionButtons />
                                    </IntercomProviderWrapper>
                                  </EzRewardsPromosProvider>
                                </LibertyWebchatProvider>
                              </GoogleMapsProvider>
                            </GlobalFulfillmentDetailProvider>
                          </CookiesProvider>
                        </FlashMessageProvider>
                      </TrackingProvider>
                    </IdentityProvider>
                  </OrderingExperienceProvider>
                </GeolocationProvider>
              </RootProvider>
            </ApolloProvider>
          </CacheProvider>
        </PageReferrerProvider>
      </LazyMotion>
    </ErrorBoundary>);
};
const __Next_Translate__Page__1932bd50352__ = StoreNextApp;

    export default __appWithI18n(__Next_Translate__Page__1932bd50352__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  