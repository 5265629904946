import {
  type CateringMenuFulfillmentDetailWithMessagesFragment,
  FulfillmentDetailStrategy,
} from '@/graphql/types';

export const fulfillmentDetailData: CateringMenuFulfillmentDetailWithMessagesFragment = {
  __typename: 'FulfillmentDetail',
  id: 'fd-1',
  editable: false,
  eventLocalTime: '',
  strategy: FulfillmentDetailStrategy.Delivery,
  address: {
    __typename: 'Address',
    id: '1',
    street: '40 Water Street',
    city: 'Boston',
    state: 'MA',
    zip: '02109',
    fullAddress: '40 Water Street, Boston, MA 02109',
  },
  corpAccount: null,
  customerMessages: [],
};

export const fulfillmentDetailWithEventTimeData: CateringMenuFulfillmentDetailWithMessagesFragment =
  {
    __typename: 'FulfillmentDetail',
    id: 'fd-2',
    editable: false,
    eventLocalTime: '12:15:00',
    eventOn: '2024-11-21',
    strategy: FulfillmentDetailStrategy.Delivery,
    address: {
      __typename: 'Address',
      id: '1',
      street: '40 Water Street',
      city: 'Boston',
      state: 'MA',
      zip: '02109',
      fullAddress: '40 Water Street, Boston, MA 02109',
    },
    corpAccount: null,
    customerMessages: [],
  };
