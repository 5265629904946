import {useEffect, useRef} from 'react';
import {datadogRum} from '@datadog/browser-rum';

import {type ConsumerAccount} from '@/graphql/types';

type UseDatadogRumSetupProps = {
  trackingId?: string;
  consumerAccount?: Pick<ConsumerAccount, 'id' | 'fullName' | 'email'> | null;
};

export function useDatadogRumSetup({trackingId, consumerAccount}: UseDatadogRumSetupProps): void {
  const hasInitiatedRef = useRef(false);
  useEffect(() => {
    if (trackingId && !hasInitiatedRef.current) {
      const id = consumerAccount?.id ?? trackingId;
      datadogRum.setUser({
        email: consumerAccount?.email,
        name: consumerAccount?.fullName ?? 'Guest User',
        id,
      });
      // this will set a custom attribute in datadog, doesn't actually get set on the DD User object.
      datadogRum.setGlobalContextProperty('user.trackingId', trackingId);
      hasInitiatedRef.current = true;
    }
  }, [trackingId, consumerAccount?.id, consumerAccount?.email, consumerAccount?.fullName]);
}
