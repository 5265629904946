import getConfig from 'next/config';

export default function appsFlyerInitialize() {
  const config = getConfig().publicRuntimeConfig;

  if (!config?.appsFlyerWebKey) {
    return;
  }

  window.AppsFlyerSdkObject = 'AF';
  window.AF =
    window.AF ||
    ((...args) => {
      (window.AF.q = window.AF.q || []).push([Date.now()].concat(args));
    });

  window.AF.id = window.AF.id || {banners: {key: config.appsFlyerWebKey}};

  window.AF.plugins = {};
  window.AF('banners', 'showBanner', {bannerZIndex: 20});
}
