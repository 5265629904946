import {
  RudderstackApiObject,
  RudderstackApiOptions,
} from '@/components/TrackingProvider/TrackingProvider';

export const getTrackConversionPayload = (
  conversionName: string,
  properties: RudderstackApiObject,
  traits: RudderstackApiOptions,
): [string, RudderstackApiObject, RudderstackApiOptions] => {
  return [
    'conversion',
    {
      ...properties,
      conversion_name: conversionName,
    },
    {
      ...traits,
      integrations: {
        All: false,
        'Facebook Conversions': true,
        Reddit: true,
        'The Trade Desk Real Time Conversions': true,
      },
    },
  ];
};
