import {FC, PropsWithChildren} from 'react';
import {IntercomProvider} from 'react-use-intercom';
import getConfig from 'next/config';

import FeatureFlags from '@/FeatureFlags';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';

type IntercomProviderWrapperProps = PropsWithChildren<unknown>;

const IntercomProviderWrapper: FC<IntercomProviderWrapperProps> = ({children}) => {
  const intercomEnabled = useFeatureFlag(FeatureFlags.IntercomEnabled);
  const config = getConfig().publicRuntimeConfig;
  const {data} = useIdentity();
  const {consumerAccount} = data?.me ?? {};
  const shouldInitialize = intercomEnabled && !!config.intercomAppId && consumerAccount != null;

  return (
    <IntercomProvider
      autoBoot
      shouldInitialize={shouldInitialize}
      appId={config.intercomAppId}
      autoBootProps={{
        name: consumerAccount?.fullName ?? undefined,
        email: consumerAccount?.email,
        userId: consumerAccount?.identityId,
        customAttributes: {'Source Website': 'demand'},
      }}
    >
      {children}
    </IntercomProvider>
  );
};

export default IntercomProviderWrapper;
