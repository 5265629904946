import type React from 'react';
import {createContext, useMemo} from 'react';
import {type IExperimentData} from '@ezcater/experiments-js/dist/types';

export const ExperimentsContext = createContext<IExperimentData>({});

type ExperimentsProviderProps = React.PropsWithChildren<{
  cache?: IExperimentData | null;
}>;

const ExperimentsProvider: React.FC<ExperimentsProviderProps> = ({cache, children}) => {
  const providerCache = useMemo(() => cache || {}, [cache]);

  return (
    <ExperimentsContext.Provider value={providerCache}>{children}</ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
