import {Button} from '@ezcater/tapas';

import {BadgeProps} from '../types';

const FloatingActionButtonWithBadge = ({
  handleClick,
  isOpen,
  closedIcon,
  openIcon,
  position,
}: BadgeProps) => {
  return (
    <div className="fixed z-50" style={position}>
      <Button
        className="flex h-12 w-12 items-center justify-center rounded-full"
        onClick={handleClick}
      >
        {isOpen ? openIcon : closedIcon}
      </Button>
      <div className="absolute right-0 top-0 z-1300 h-3 w-3 rounded-full border-2 border-white bg-guava-500"></div>
    </div>
  );
};

export default FloatingActionButtonWithBadge;
