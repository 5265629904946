const appBorderRadius = {
  borderRoundedNone: '0',
  borderRoundedSm: '0.125rem',
  borderRounded: '0.25rem',
  borderRoundedMd: '0.375rem',
  borderRoundedLg: '0.5rem',
  borderRoundedXl: '0.75rem',
  borderRounded2xl: '1rem',
  borderRounded3xl: '1.5rem',
  borderRoundedFull: '9999px',
};

export default appBorderRadius;
