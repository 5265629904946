import gtm from './gtm';

export enum MicroConversionEnum {
  enterHeadcount = 'micro-conversion-enter-headcount',
  enterEventName = 'micro-conversion-enter-event-name',
  updateEventDateTime = 'micro-conversion-update-event-date-time',
  updateOrderType = 'micro-conversion-update-order-type',
  checkout = 'micro-conversion-checkout',
  mobileCheckout = 'micro-conversion-mobile-checkout',
  updateOrderCorpAccount = 'micro-conversion-update-order-corp-account',
}

const trackMicroConversion = (event: MicroConversionEnum): void => {
  gtm.dataLayer({event});
};

export default trackMicroConversion;
