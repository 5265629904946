import {useEffect, useRef} from 'react';

import {type ConsumerAccount} from '@/graphql/types';

type UseFullStorySetupProps = {
  isEnabled: boolean;
  trackingId?: string;
  consumerAccount?: Pick<ConsumerAccount, 'fullName' | 'email' | 'registrationStatus'> | null;
};

const useFullStorySetup = ({
  isEnabled,
  trackingId,
  consumerAccount,
}: UseFullStorySetupProps): void => {
  const hasIdentifiedRef = useRef(false);
  useEffect(() => {
    if (isEnabled && trackingId && !hasIdentifiedRef.current) {
      window.FS.identify(trackingId, {
        name: consumerAccount?.fullName ?? null,
        email: consumerAccount?.email ?? null,
        status: consumerAccount?.registrationStatus ?? null,
      });
      hasIdentifiedRef.current = true;
    }
  }, [
    isEnabled,
    trackingId,
    consumerAccount?.fullName,
    consumerAccount?.email,
    consumerAccount?.registrationStatus,
  ]);
};

export default useFullStorySetup;
