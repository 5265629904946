globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"031e73e28b23bbcb0c686355f7ddd045bfcf03a6"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import {datadogRum} from '@datadog/browser-rum';
import {type ErrorEvent, globalHandlersIntegration, init} from '@sentry/nextjs';
import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig();

const IGNORED_THIRD_PARTY_BREADCRUMB_URLS = [
  'https://253-ct.c3tag.com/',
  'https://api.hubspot.com/',
  'https://cdn.transcend.io/',
  'https://ct.pinterest.com/',
  'https://edge.fullstory.com/',
  'https://rs.fullstory.com/',
  'https://forms.hubspot.com/',
  'https://maps.googleapis.com/',
  'https://ampcid.google.com/',
  'https://browser-intake-datadoghq.com/',
  'https://s.yimg.com/',
  'https://tags.srv.stackadapt.com/',
  'https://ws.zoominfo.com/',
  'https://cm.teads.tv/',
  'https://js.zi-scripts.com/',
  'https://evnt.byspotify.com/',
  'https://intentstream.contanuity.com/',
  'https://app.leadsrx.com/',
  'https://imp.i319830.net/',
  'https://www.redditstatic.com/',
  'https://conversions-config.reddit.com/',
  'https://js.zi-scripts.com/',
  'https://t.teads.tv/',
  'https://u.clarity.ms/',
  'https://px.ads.linkedin.com/',
];

if (publicRuntimeConfig.sentryClientDsn) {
  init({
    enabled: process.env.NODE_ENV === 'production' && window.location.protocol !== 'file:',
    dsn: publicRuntimeConfig.sentryClientDsn,
    environment: publicRuntimeConfig.environment,
    release: process.env.CONFIG_BUILD_ID,
    integrations: [globalHandlersIntegration({onerror: false, onunhandledrejection: true})],
    allowUrls: [
      /\.ezcater\.com/i,
      /\.staging-ezcater.com/i,
      /\.sandbox-ezcater.com/i,
      /\.cdn-ezcater.com/i,
      // google maps
      /\.googleapis.com/i,
    ],
    beforeSend(event, hint) {
      // ignore reporting unhandled errors unless it came from GraphQL
      if (hint.mechanism?.handled === false && hint.mechanism.type === 'onunhandledrejection') {
        const error = hint.originalException as Error;
        if (
          error?.name !== 'ApolloError' &&
          !event.breadcrumbs?.slice(-10).find(crumb => crumb.category === 'GraphQL Error')
        ) {
          return null;
        }
      }

      const additionalTags: NonNullable<ErrorEvent['tags']> = {
        datadogSessionReplayLink: datadogRum.getSessionReplayLink(),
      };
      if (typeof window.FS?.getCurrentSessionURL === 'function') {
        additionalTags.fullstoryLink = window.FS.getCurrentSessionURL(true);
      }

      return {
        ...event,
        tags: {...event.tags, ...additionalTags},
      };
    },
    beforeBreadcrumb(breadcrumb) {
      // ignore reporting breadcrumbs for our third party scripts,
      // as long as they are HTTP OK (200 <= {http status code} < 300)
      if (
        (breadcrumb.category === 'fetch' || breadcrumb.category === 'xhr') &&
        breadcrumb.data?.status_code >= 200 &&
        breadcrumb.data?.status_code < 300 &&
        breadcrumb.data?.url &&
        IGNORED_THIRD_PARTY_BREADCRUMB_URLS.some(url => breadcrumb.data?.url?.startsWith(url))
      ) {
        return null;
      }

      return breadcrumb;
    },
  });
}
