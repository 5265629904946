import {
  AppBarFulfillmentDetailFragment,
  DeliverySetup,
  FulfillmentDetail,
  FulfillmentDetailStrategy,
} from '@/graphql/types';

export const getFulfillmentDetail = (street = '40 Water St') =>
  ({
    __typename: 'FulfillmentDetail',
    id: 'fulfillment-detail-test-id-1',
    address: {
      __typename: 'Address',
      id: 'address-test-id-1',
      city: 'Boston',
      fullAddress: `${street}, Boston, MA 02109`,
      latitude: 42.3577849,
      longitude: -71.0569732,
      metropolitanStatisticalArea: 'Boston-Worcester-Lawrence MA-NH-ME-CT CMSA',
      name: 'ezCater Office',
      nameOrLocation: '',
      state: 'MA',
      street,
      timeZoneIdentifier: 'US/Eastern',
      zip: '02109',
      uuid: 'address-test-uuid',
    },
    consumerCarts: [],
    corpAccount: null,
    customerMessages: [],
    deliverySetup: DeliverySetup.None,
    dietaryPoll: null,
    editable: true,
    eventAt: null,
    eventLocalTime: '13:15:00',
    eventName: 'Wonder Wharf Party',
    eventOn: '2007-08-01',
    headcount: 100,
    strategy: FulfillmentDetailStrategy.Delivery,
    usingConsumerCart: false,
  }) satisfies FulfillmentDetail;

export const getFulfillmentDetailFragment = () => {
  const fullFD = getFulfillmentDetail();
  const {
    id,
    address: fdAddress,
    editable,
    eventLocalTime,
    eventAt,
    eventName,
    eventOn,
    headcount,
    strategy,
  } = fullFD;
  return {
    __typename: 'FulfillmentDetail',
    id,
    address: {
      __typename: 'Address',
      id: fdAddress.id,
      name: fdAddress.name,
      street: fdAddress.street,
      city: fdAddress.city,
      state: fdAddress.state,
      zip: fdAddress.zip,
      latitude: fdAddress.latitude,
      longitude: fdAddress.longitude,
      fullAddress: fdAddress.fullAddress,
    },
    editable,
    eventLocalTime,
    eventAt,
    eventName,
    eventOn,
    headcount,
    strategy,
  } satisfies AppBarFulfillmentDetailFragment;
};
