import {datadogRum} from '@datadog/browser-rum';
import getConfig from 'next/config';

if (typeof window !== 'undefined') {
  const {
    publicRuntimeConfig: {
      datadog: {rumClientToken, rumApplicationId},
      environment,
      graphqlHost,
    },
  } = getConfig();
  // as of datadog sdk version 4 the version, environment, and service
  // must all start with a letter (see: https://docs.datadoghq.com/getting_started/tagging/#defining-tags)
  // our build IDs always start with a number, so we add "v" to the beginning for datadog to be happy
  const version = `v${process.env.CONFIG_BUILD_ID}`;

  if (rumClientToken && rumApplicationId) {
    datadogRum.init({
      allowedTracingUrls: [new URL(graphqlHost).origin],
      applicationId: rumApplicationId,
      clientToken: rumClientToken,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['feature_flags'],
      env: environment,
      service: 'store-next',
      sessionReplaySampleRate: 100,
      sessionSampleRate: 100,
      site: 'datadoghq.com',
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version,
    });
  }

  (window as any)._fs_ready = () => {
    const fullstoryLink = window.FS.getCurrentSessionURL();

    datadogRum.setGlobalContextProperty('fullstoryLink', fullstoryLink);
  };
}
