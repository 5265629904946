import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {Loader} from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: '',
  client: 'gme-ezcaterinc',
  libraries: ['places'],
});

type GoogleMapsContextPayload = {
  active: boolean;
  libraries: {
    places: google.maps.PlacesLibrary | null;
  };
};

export const GoogleMapsContext = createContext<GoogleMapsContextPayload>({
  active: false,
  libraries: {places: null},
});

export const useGoogleMapsAPI = () => useContext(GoogleMapsContext);

const GoogleMapsProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [libraries, setLibraries] = useState<GoogleMapsContextPayload['libraries']>({places: null});

  useEffect(() => {
    loader
      .importLibrary('places')
      .then(places => {
        setIsLoaded(true);
        setLibraries(currentLibraries => ({...currentLibraries, places}));
      })
      .catch(() => {}); // Do nothing as we don't control google maps API
  }, []);

  return (
    <GoogleMapsContext.Provider
      value={useMemo(() => ({active: isLoaded, libraries}), [isLoaded, libraries])}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};

export default GoogleMapsProvider;
