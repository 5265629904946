/*
 * This file sets up the global variables used by various
 * third party scripts. Currently it contains the minified
 * code for:
 *
 * * Google Tag Manager
 * * FullStory
 * * AppsFlyer
 * * Mutiny
 *
 * The snippets used by those third party libraries have been
 * modified, removing the actual script injection which is
 * handled by the GlobalScripts component. All that's left is
 * the initial object creation that these libraries ask to be included.
 *
 * For reasoning(s) and an example of why we implement 3rd party scripts this way, see this post:
 * https://ezcater.atlassian.net/wiki/spaces/POL/pages/4218880089/Third+Party+Scripts+in+Store+Next
 */
import getConfig from 'next/config';

import appsFlyerInitialize from './appsFlyerInitialize';
import mutinyInitialize from './mutinyInitialize';

if (typeof window !== 'undefined') {
  // Google Tag Manager init
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});

  // FullStory init
  /* eslint-disable prefer-rest-params */
  const FS = (n: string, e?: any, t?: any) => {
    if (FS.q) FS.q.push([n, e, t]);
    else (FS as any)._api(n, e, t);
  };
  FS.q = [] as any[];
  FS.identify = (uid: any, e?: any, t?: any) => {
    FS('user', {uid}, t);
    if (e) FS('user', e, t);
  };
  FS.setUserVars = (n: any, e: any) => {
    FS('user', n, e);
  };
  FS.event = (n: any, p: any, t: any) => {
    FS('event', {n, p}, t);
  };
  FS.anonymize = () => {
    FS.identify(false);
  };
  FS.shutdown = () => {
    FS('rec', false);
  };
  FS.restart = () => {
    FS('rec', true);
  };
  FS.log = (n: any, e: any) => {
    FS('log', [n, e]);
  };
  FS.consent = function (n: any) {
    FS('consent', !arguments.length || n);
  };
  FS.identifyAccount = (n: any, e: any) => {
    const payload = e || {};
    payload.accId = n;
    FS('account', payload);
  };
  FS.clearUserCookie = () => {};
  FS.setVars = (n: any, e: any) => {
    FS('setVars', [n, e]);
  };
  FS._w = {
    XMLHttpRequest: window.XMLHttpRequest,
    fetch: window.fetch,
  };
  FS._v = '1.3.0';

  if (window.fetch) {
    window.fetch = function () {
      return FS._w.fetch.apply(this, arguments as unknown as Parameters<typeof fetch>);
    };
  }

  window._fs_debug = __DEV__;
  window._fs_host = 'fullstory.com';
  window._fs_script = 'edge.fullstory.com/s/fs.js';
  window._fs_org = getConfig().publicRuntimeConfig?.fullStoryOrgKey;
  window._fs_namespace = 'FS';
  window.FS = FS;

  // AppsFlyer init
  appsFlyerInitialize();
  /* eslint-enable */

  // Mutiny init
  mutinyInitialize();
}

// No exports are used, this informs TypeScript
// that this file is a module can be imported
export {};
