import {useEffect} from 'react';
import {useCookies} from 'react-cookie';

import gtm from '@/utils/gtm';

export const GOOGLE_ANALYTICS_COOKIE = 'ez_ga_events';

const DOMAIN_REGEXP = /(?!www)(\..*(?:.com)|.*(?=:)|localhost)/;
const PATH = '/';

type EventProps = {
  event: string;
  user_marketplace_status?: string;
  user_type?: string;
};

const getDomain = (host: string): string => host.match(DOMAIN_REGEXP)?.[0] ?? '.ezcater.com';

const useGoogleAnalyticsCookieEventNotifier = (): void => {
  const [cookies, , removeCookie] = useCookies([GOOGLE_ANALYTICS_COOKIE]);
  const events: [EventProps?] = cookies[GOOGLE_ANALYTICS_COOKIE];

  useEffect(() => {
    if (events) {
      if (Array.isArray(events)) {
        events.forEach((eventProps?: EventProps): void => {
          gtm.dataLayer({...eventProps});
        });
      }

      const domain = getDomain(window.location.host);

      removeCookie(GOOGLE_ANALYTICS_COOKIE, {domain, path: PATH});
    }
  }, [events, removeCookie]);
};

export default useGoogleAnalyticsCookieEventNotifier;
