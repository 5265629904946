import {type SearchPageObject} from '@/pageUtils/caterer-search/cateringSearch';
import {CATERING_MENU_PATH} from '@/paths';
import {compilePath} from '@/utils';

export const searchPageData = {
  address: {
    __typename: 'Address',
    id: '299638405',
    city: 'Boston',
    fullAddress: '40 Water St, Boston, MA 02109',
    latitude: 42.3577849,
    longitude: -71.0569732,
    metropolitanStatisticalArea: 'Boston-Worcester-Lawrence MA-NH-ME-CT CMSA',
    state: 'MA',
    street: '40 Water St',
    timeZoneIdentifier: 'America/New_York',
    zip: '02109',
  },
  catererUrlId: null,
  consumerAccount: null,
  dietaryPollBannerData: {
    cutoffTime: '2007-09-01T10:30:00-05:00',
    eventName: 'Test Party',
    id: 'poll-1',
    responseCount: 2,
  },
  editable: true,
  eventLocalTime: 1200,
  eventName: 'Test Party',
  eventOn: '2007-08-01',
  fulfillmentDetailId: 'fulfillment-detail-1',
  generateLinkToCaterer: catererUrlId => compilePath(CATERING_MENU_PATH, {catererUrlId}),
  headcount: 50,
  isAdmin: false,
  isCartEmpty: true,
  nationwideCatererCount: 100_000,
  strategy: 'DELIVERY',
  trackingId: 'tracking-1',
  usingConsumerCart: true,
  utcOffsetMinutes: -300,
} satisfies SearchPageObject;
