import {type ApolloLink, type Operation} from '@apollo/client';
import {type NetworkError} from '@apollo/client/errors';
import {onError} from '@apollo/client/link/error';
import {addBreadcrumb, captureException, withScope} from '@sentry/nextjs';
import {type ExecutionResult} from 'graphql';

const GRAPHQL_ERROR_CATEGORY = 'GraphQL Error';
const GRAPHQL_ERROR_DEFAULT_MESSAGE = 'Unknown GraphQL error';
const PROTECTED_VARIABLE_NAME_FRAGMENTS = ['password', 'token', 'secret', 'api'];

const scrubVariables = (variables: Record<string, unknown> = {}) => {
  const safeVars: Record<string, unknown> = {};

  Object.keys(variables).forEach(key => {
    const variableName = key.toLowerCase();
    const isProtected = PROTECTED_VARIABLE_NAME_FRAGMENTS.some(protectedFragment =>
      variableName.includes(protectedFragment),
    );

    safeVars[key] = isProtected ? '[filtered]' : variables[key];
  });

  return safeVars;
};

const formatGraphQLErrorData = (operation: Operation) => ({
  operationName: operation?.operationName,
  variables: JSON.stringify(scrubVariables(operation?.variables)),
});

const handleNetworkError = ({
  operation,
  networkError,
}: {
  operation: Operation;
  networkError?: NetworkError;
}) => {
  if (networkError) {
    withScope(scope => {
      scope.setExtras(formatGraphQLErrorData(operation));
      captureException(networkError);
    });

    if (__DEV__)
      console.error(
        'Encounted a network error for operation ',
        operation.operationName,
        networkError,
      );
  }
};

const handleGraphQLError = ({
  operation,
  response,
}: {
  operation: Operation;
  response?: ExecutionResult;
}) => {
  const errors = response?.errors ?? [];

  if (errors.length === 0) return;

  addBreadcrumb({
    category: GRAPHQL_ERROR_CATEGORY,
    message: errors
      .map(err => err.message ?? GRAPHQL_ERROR_DEFAULT_MESSAGE)
      .filter(Boolean)
      .join('; '),
    level: 'info',
    data: {
      errors: JSON.stringify(errors),
      ...formatGraphQLErrorData(operation),
    },
  });
};

const createSentryLink = (): ApolloLink =>
  onError(({networkError, operation, response}) => {
    try {
      handleGraphQLError({operation, response});
      handleNetworkError({operation, networkError});
    } catch (error) {
      captureException(error);
    }
  });

export default createSentryLink;
