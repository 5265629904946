import {type ApolloLink, HttpLink, type HttpOptions} from '@apollo/client';
import fetch from 'cross-fetch';
import getConfig from 'next/config';

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig();
const {graphqlHost} = typeof window === 'undefined' ? serverRuntimeConfig : publicRuntimeConfig;

export const uri = process.env.NODE_ENV === 'test' ? 'http://localhost:21216/graphql' : graphqlHost;

const httpLinkOptions: HttpOptions = {uri, credentials: 'include'};
if (typeof window !== 'undefined') {
  httpLinkOptions.fetch = fetch;
}

const createHttpLink = (): ApolloLink => new HttpLink(httpLinkOptions);

export default createHttpLink;
